import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import { Spiner } from '@src/components/Spiner';
import { authenticationSeletor, errorMessageSeletor } from '@src/selectors/commonSelector';
import { IAppState } from '@src/interfaces/IAppState';
import ErrorPage from '../../pages/_error';
import { login } from '@src/actions/userActions';
import { checkTokenIsExpired, getAppVersion, getPathHasLastSlash, versionCompare } from '@src/utils/functionUtils';
import { setToggleAuthenticating, setErrorMessage } from '@src/actions/commonActions';
import { IAuthentication, IErrorMessage } from '@src/interfaces/ICommonState';
import { ERROR_RESPONSE_TYPES, errorResponseType } from '@src/utils/errorHandler';
import Router from 'next/router';
import { pathConstant, EventName, PATH_ROUTE } from '@src/utils/appContanst';
import { getBalanceInfoSuccess } from '@src/actions/balanceActions';
import { isAndroid } from '@src/utils/domUtil';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const withAppAuthenticating = (ComposedComp: any) => {
  class AppLoadedAuthenticating extends React.Component<any, any> {
    constructor(props: any) {
      super(props);
      this.state = { isCheckingTokenExpired: true };
      this.initialDataFromAuApp = this.initialDataFromAuApp.bind(this);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    UNSAFE_componentWillMount() {
      this.props.setErrorMessage({ title: '', message: '', statusCode: '' });
    }

    componentDidMount() {
      const { router, emoney } = this.props;

      this.handleUserLogin();
      window.addEventListener(EventName.GET_INITIAL_DATA_ASSET_TOP, this.initialDataFromAuApp as EventListenerOrEventListenerObject);
      // initial data from au app incase balanceReducer has not existed data yet.
      const { userAgent } = window.navigator;
      const appVersion = getAppVersion(userAgent);
      const android = isAndroid();
      const {
        top,
        balance_detail,
        report
      } = PATH_ROUTE;
      const fullPathname = getPathHasLastSlash(router.pathname);
      const isIncludesListPage = ([
        top,
        balance_detail,
        report
      ] as Array<string>).includes(fullPathname);

      if (isIncludesListPage && !emoney.accounts.length) {
        // only call incase appVersion: android >= 9.20.2 & ios >= 9.20.1
        if ((android && versionCompare(appVersion, '9.20.2') >= 0) || (!android && versionCompare(appVersion, '9.20.1') >= 0)) {
          window.location.href = 'auwallet://setusercondition';
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentDidUpdate(prevProps: any) {
      if (prevProps.errorMessage !== this.props.errorMessage) {
        if (this.props.errorMessage.statusCode === 404) {
          Router.push(pathConstant.topPath);
        }
      }
    }

    initialDataFromAuApp(event: CustomEvent) {
      this.props.getBalanceInfoSuccess(event.detail.data);
    }

    handleUserLogin = () => {
      // login again
      const vtkt = Cookies.get('VTKT') || Cookies.get('vtkt') || '';
      const tokenIsExpired: boolean = checkTokenIsExpired();

      if (!vtkt) {
        this.props.setToggleAuthenticating({ isAuthenticating: false, isAuthenSuccess: false });
      } else if (tokenIsExpired) {
        this.props.setToggleAuthenticating({ isAuthenticating: true });
        this.props.login();
      }
      this.setState({ isCheckingTokenExpired: false });
    };

    render() {
      const { authentication } = this.props;
      const { isCheckingTokenExpired } = this.state;
      const { statusCode } = this.props.errorMessage;
      const errorType = errorResponseType(statusCode);

      if (authentication.isAuthenticating || isCheckingTokenExpired) {
        if (['/asset', '/asset/'].includes(this.props.router.route)) {
          return <ComposedComp {...this.props} />;
        }
        return <Spiner />;
      }

      if (errorType !== ERROR_RESPONSE_TYPES.NONE) {
        if (errorType === ERROR_RESPONSE_TYPES.NOT_FOUND) {
          return <Spiner />;
        }
        return <ErrorPage
          statusCode={statusCode}
          errorResponseType={errorType}
          sentryMsg={'Call Authorize failed'}
          message={'無許可'} />;
      }

      // Show error if VTKT is not existed or authen fail
      if (!authentication.isAuthenticating && !authentication.isAuthenSuccess) {
        return <ErrorPage
          statusCode={401}
          message={'無許可'}
          sentryMsg={'No VTKT'}
          errorResponseType={ERROR_RESPONSE_TYPES.OTHERS_TYPES}/>;
      }
      return (
        <ComposedComp {...this.props}/>
      );
    }
  }

  const mapStateToProps = (state: IAppState) => ({
    authentication: authenticationSeletor(state),
    errorMessage: errorMessageSeletor(state),
    isAuthenSuccess: state.commonState.authentication.isAuthenSuccess || false,
    emoney: state.balanceState.e_money
  });

  const mapDispatchToProps = (dispatch: Dispatch) => ({
    login: () => dispatch(login()),
    setErrorMessage: (errorMessage: IErrorMessage) => dispatch(setErrorMessage(errorMessage)),
    setToggleAuthenticating: (authentication: IAuthentication) => dispatch(setToggleAuthenticating(authentication)),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getBalanceInfoSuccess: (data: any) => dispatch(getBalanceInfoSuccess(data))
  });

  return connect(mapStateToProps, mapDispatchToProps)(AppLoadedAuthenticating);
};
