import { IErrorMessage, IAuthentication } from '@src/interfaces/ICommonState';
import { IAnlyticData } from '@src/interfaces/IAnlyticData';

export const actionTypes = {
  COMMON_SET_LOADING: 'COMMON_SET_LOADING',
  COMMON_TOGGLE_MODAL_SETTING_PROFILE: 'COMMON_TOGGLE_MODAL_SETTING_PROFILE',
  COMMON_TOGGLE_SET_AUTHENTICATING: 'COMMON_TOGGLE_SET_AUTHENTICATING',
  COMMON_SET_ERROR_MESSAGE: 'COMMON_SET_ERROR_MESSAGE',
  COMMON_RE_SERVEY_LIFE_STYLE: 'COMMON_RE_SERVEY_LIFE_STYLE',
  COMMON_SET_LOADING_ASSET: 'COMMON_SET_LOADING_ASSET',
  COMMON_TOGGLE_MODAL_INPUT: 'COMMON_TOGGLE_MODAL_INPUT',
  UPDATE_FILTER: 'UPDATE_FILTER',
  UPDATE_ACTIVE_TAB: 'UPDATE_ACTIVE_TAB',
  SAVE_DATA_ANALYTIC: 'SAVE_DATA_ANALYTIC',
  UPDATE_SKIP_SCROLL_TOP: 'UPDATE_SKIP_SCROLL_TOP',
  UPDATE_EDITING_STATUS: 'UPDATE_EDITING_STATUS',
  HANDLE_CANCEL_EDITING_STATUS: 'HANDLE_CANCEL_EDITING_STATUS'
};

export const setLoading = (isLoading: boolean) => ({
  type: actionTypes.COMMON_SET_LOADING,
  isLoading
});

export const setToggleModalSettingProfile = (isOpenSettingProfile: boolean) => ({
  type: actionTypes.COMMON_TOGGLE_MODAL_SETTING_PROFILE,
  isOpenSettingProfile
});

export const setToggleAuthenticating = (authentication: IAuthentication) => ({
  type: actionTypes.COMMON_TOGGLE_SET_AUTHENTICATING,
  data: authentication
});

export const setErrorMessage = (errorMessage: IErrorMessage) => ({
  type: actionTypes.COMMON_SET_ERROR_MESSAGE,
  data: errorMessage
});

export function setReSurveyLifeStyle(reLifeStyle: boolean) {
  return {
    type: actionTypes.COMMON_RE_SERVEY_LIFE_STYLE,
    reLifeStyle
  };
}

export const setLoadingAsset = (isLoadingAsset: boolean) => ({
  type: actionTypes.COMMON_SET_LOADING_ASSET,
  isLoadingAsset
});

export const setToggleModalInput = (isOpenInput: boolean) => ({
  type: actionTypes.COMMON_TOGGLE_MODAL_INPUT,
  isOpenInput
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateFilter(filterSelect: any) {
  return {
    type: actionTypes.UPDATE_FILTER,
    filterSelect
  };
}

export function updateTabselect(activeTab: number) {
  return {
    type: actionTypes.UPDATE_ACTIVE_TAB,
    activeTab
  };
}

export function postAnalyticData(anlyticData: IAnlyticData) {
  return {
    type: actionTypes.SAVE_DATA_ANALYTIC,
    anlyticData
  };
}

export function updateSkipScrollTop(skipScrollTop: boolean) {
  return {
    type: actionTypes.UPDATE_SKIP_SCROLL_TOP,
    skipScrollTop
  };
}

export function handleCancelEditingStatus() {
  return {
    type: actionTypes.HANDLE_CANCEL_EDITING_STATUS
  };
}

export function updateEditingStatus(isEditing: boolean) {
  return {
    type: actionTypes.UPDATE_EDITING_STATUS,
    isEditing
  };
}
