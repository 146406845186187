import React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  selectorElement: 'portal-modal' | string;
  children: React.ReactNode;
}

export class Portal extends React.PureComponent<PortalProps> {
  rootElement: HTMLDivElement | null;

  constructor(props: PortalProps) {
    super(props);
    this.rootElement = null;
  }

  componentDidMount() {
    if (this.props.selectorElement) {
      this.rootElement = document.querySelector(this.props.selectorElement);
      this.forceUpdate();
    }
  }

  render() {
    if (typeof this.rootElement === 'undefined' || !this.rootElement) {
      return null;
    }

    return ReactDOM.createPortal(this.props.children, this.rootElement);
  }
}
