import * as React from 'react';
import Link from 'next/link';
import { IButton } from '@src/interfaces/IButton';

interface KIButton extends IButton {
  hasArrow?: boolean
}

const ButtonOrange: React.FunctionComponent<KIButton> = ({ className: passedClasses, hasArrow, ...props }: KIButton) => {
  const className = `button is-kpfm is-orange ${passedClasses || ''}`;

  if (props.href) {
    return (
      <Link href={props.href}>
        <a {...props} className={className}>
          <span>
            {props.children}
            {hasArrow && <i className="list-small--arrow kicon-arrow-grey--white" /> }
          </span>
        </a>
      </Link>
    );
  }

  return (
    <button {...props} onClick={props.onClick} className={`${className}`}>
      <span>
        {props.children}
        {hasArrow && <i className="list-small--arrow kicon-arrow-grey--white" /> }
      </span>
    </button>
  );
};

export { ButtonOrange };
