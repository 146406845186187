import { IAppState } from '@src/interfaces/IAppState';

export const commonStateSeletor = (state: IAppState) => (
  state.commonState
);

export const isLoadingSeletor = (state: IAppState) => (
  state.commonState.isLoading
);

export const skipScrollTopSelector = (state:IAppState) => (
  state.commonState.skipScrollTop
);

export const isOpenSettingProfileSeletor = (state: IAppState) => (
  state.commonState.isOpenSettingProfile
);
export const authenticationSeletor = (state: IAppState) => (
  state.commonState.authentication
);

export const errorMessageSeletor = (state: IAppState) => (
  state.commonState.errorMessage
);

export const reLifeStyleSeletor = (state: IAppState) => (
  state.commonState.reLifeStyle
);

export const isLoadingAssetSeletor = (state: IAppState) => (
  state.commonState.isLoadingAsset
);
