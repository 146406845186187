import * as React from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { ButtonOutlined } from '@src/components/BaseButton';
import { kabucomTermContent, kabucomTermTitle } from '@src/static_data/term_of_use';

interface ModalTermOfUseAustockProps {
  onCloseModal?(): void;
  isOpenModal?: boolean;
}

export class ModalTermOfUseAustock extends React.Component<ModalTermOfUseAustockProps, {}> {
  componentDidUpdate(prevProps: ModalTermOfUseAustockProps) {
    if (prevProps.isOpenModal !== this.props.isOpenModal && this.props.isOpenModal === true) {
      disableBodyScroll(document.getElementsByClassName('modal-bodycontent')[0]);
    } else if (prevProps.isOpenModal !== this.props.isOpenModal && this.props.isOpenModal === false) {
      enableBodyScroll(document.getElementsByClassName('modal-bodycontent')[0]);
      clearAllBodyScrollLocks();
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    const { onCloseModal, isOpenModal } = this.props;
    return (
      <React.Fragment>
        {
          isOpenModal &&
          <div className={'modal kpfm-modal kpfm-modal-term is-demo'}>
            <div className="modal-head-term">
              <div className="close-menu" onClick={onCloseModal}>
                <img
                  src="/static/media/close.png"
                  alt="Close menu"
                />
              </div>
              <h3>
                {kabucomTermTitle}
              </h3>
            </div>
            <div className="modal-content-term mobile-max-height">
              <React.Fragment>
                <div className='modal-bodycontent content popup-termofuse-kabucom' dangerouslySetInnerHTML={{ __html: kabucomTermContent }} />
                <div className="modal-bottom-content modal-bottom-term">
                  <ButtonOutlined
                    id="modal-policy"
                    onClick={onCloseModal}
                    className="btn-term"
                  >
                      閉じる
                  </ButtonOutlined>
                </div>
              </React.Fragment>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}
