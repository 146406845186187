import React from 'react';
import Router, { SingletonRouter } from 'next/router';
import { withRouterParsedQuery } from '@src/hocs/withRouterParsedQuery';
import { pathConstant, PATH_ROUTE } from '@src/utils/appContanst';
import { getPathHasLastSlash, handleScrollTop } from '@src/utils/functionUtils';
import { Dispatch } from 'redux';
import { IAppState } from '@src/interfaces/IAppState';
import { connect } from 'react-redux';
import { withLinkAccount } from '@src/hocs/withLinkAccount';
import { resetTimeParam } from '@src/actions/historyActions';
import { show } from 'redux-modal';
import { handleBackInReportSetting, updateReportType } from '@src/actions/reportActions';
import { footerNavAnalyticEvents } from '@src/analyticsEvents/footerAnalyticsEvent';

interface FooterMenuNavProps {
  query: {[key: string]: string};
  router: SingletonRouter;
  isEditing: boolean;
  isLoading: boolean;
  isOpenMenu: boolean;
  resetTimeParam(): void;
  resetReportType(reportType: string): void;
  showModalConfirmCancelEdit: () => void;
  handleBackInReportSetting: () => void;
  onToggleMenu: (isOpen: boolean) => void;
}

interface State {
  backPrevPage: boolean;
  closeMenu: boolean;
}

class FooterNav extends React.PureComponent<FooterMenuNavProps, State> {
  constructor(props: FooterMenuNavProps) {
    super(props);

    this.state = {
      backPrevPage: false,
      closeMenu: false
    };
  }

  handleGoLink = (href: string) => {
    const { resetTimeParam, resetReportType } = this.props;
    const pathname = getPathHasLastSlash(Router.pathname);

    if ([pathname].includes(href)) {
      this.handleCloseMenu();
    } else {
      const isHistory = href === '/history/all/';
      const isReport = href === '/report/';

      // becau.se incase user click back btn from transaction detail,
      // we are using time param in store to handle
      if (isHistory) {
        resetTimeParam();
        footerNavAnalyticEvents.eventClickFooterNavHistory();
      } else if (isReport) {
        resetReportType('week');
        footerNavAnalyticEvents.eventClickFooterNavReport();
      }

      Router.push(href);
    }
  }

  gotoTopPage = () => {
    const pathname = getPathHasLastSlash(Router.pathname);
    if (pathname === pathConstant.topPath) {
      this.handleCloseMenu();
    } else {
      Router.push(pathConstant.topPath);
      footerNavAnalyticEvents.eventClickFooterNavTop();
    }
  }

  handleCloseMenu = () => {
    const { onToggleMenu } = this.props;
    onToggleMenu(false);
    footerNavAnalyticEvents.eventClickMenuList();
  }

  handleOpenMenu = () => {
    const { onToggleMenu } = this.props;
    onToggleMenu(true);
    footerNavAnalyticEvents.eventClickMenuList();
  }

  render() {
    const { router, isOpenMenu } = this.props;
    const currentPath = getPathHasLastSlash(router.pathname);
    const isTop = !isOpenMenu && PATH_ROUTE.top.includes(currentPath);
    const isHistory = !isOpenMenu && PATH_ROUTE.historyAll.includes(currentPath);
    const isReport = !isOpenMenu && PATH_ROUTE.report.includes(currentPath);

    return (
      <div className='footer-menu-nav'>
        <div className='footer-menu-containter'>
          <div className='footer-menu'>
            <ul className='footer-menu-nav__list'>
              <li className={`footer-menu-nav__list--item-top ${isTop ? 'active' : ''}`} onClick={isTop
                ? handleScrollTop
                : this.gotoTopPage}>
              </li>
              <li className={ `footer-menu-nav__list--item-history ${isHistory ? 'active' : ''}`} onClick={isHistory
                ? handleScrollTop
                : () => this.handleGoLink('/history/all/')}>
              </li>
              <li className={`footer-menu-nav__list--item-report ${isReport ? 'active' : ''}`} onClick={isReport
                ? handleScrollTop
                : () => this.handleGoLink('/report/')}>
              </li>
              <li className={`footer-menu-nav__list--item-menu ${isOpenMenu ? 'active' : ''}`} onClick={isOpenMenu
                ? () => this.handleCloseMenu()
                : () => this.handleOpenMenu()}>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  isEditing: state.commonState.isEditing,
  isLoading: state.commonState.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetTimeParam: () => dispatch(resetTimeParam()),
  resetReportType: (reportType: string) => dispatch(updateReportType(reportType)),
  showModalConfirmCancelEdit: () => dispatch(show('CONFIRM_CANCEL_EDIT_MODAL')),
  handleBackInReportSetting: () => dispatch(handleBackInReportSetting())
});

export default withRouterParsedQuery(connect(
  mapStateToProps,
  mapDispatchToProps
)(withLinkAccount(FooterNav)));
