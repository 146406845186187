import { IStockProductGroupState } from '@src/interfaces/IHistoryState';
import { IStockProductState } from '@src/interfaces/IStockProductState';
import { ITransactionItem } from '@src/interfaces/ITransactionState';
import { TransactionType } from '@src/utils/appContanst';

export const actionTypes = {
  LOAD_HISTORY: 'LOAD_HISTORY',
  LOAD_ALL_HISTORIES: 'LOAD_ALL_HISTORIES',
  LOAD_HISTORY_PENDING: 'LOAD_HISTORY_PENDING',
  LOAD_HISTORY_SUCCESS: 'LOAD_HISTORY_SUCCESS',
  UPDATE_PARAMS: 'UPDATE_PARAMS',
  FILTER_TRANSACTIONS: 'FILTER_TRANSACTIONS',
  UPDATE_TRANSACTION_LIST: 'UPDATE_TRANSACTION_LIST',
  RESET_TIME_PARAM: 'RESET_TIME_PARAM',
  REMOVE_ITEM_IN_TRANSACTION_LIST: 'REMOVE_ITEM_IN_TRANSACTION_LIST',
  LOAD_STOCK_PRODUCTS_SUCCESS: 'LOAD_STOCK_PRODUCTS_SUCCESS',
  GET_DATA_AU_PAY_HISTORY: 'GET_DATA_AU_PAY_HISTORY'
};

export const getDataAuPayHistory = (time: string, service: string) => ({
  type: actionTypes.GET_DATA_AU_PAY_HISTORY,
  time,
  service
});

export function loadListHistory(params: {time: string, account_id: string}) {
  return {
    type: actionTypes.LOAD_HISTORY,
    params
  };
}

export function loadAllHistories(params: {time: string}) {
  return {
    type: actionTypes.LOAD_ALL_HISTORIES,
    params
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadListHistorySuccess(data: any) {
  return {
    type: actionTypes.LOAD_HISTORY_SUCCESS,
    data
  };
}

export function loadStockProductSuccess(stocks: Array<IStockProductState>, products: IStockProductGroupState) {
  return {
    type: actionTypes.LOAD_STOCK_PRODUCTS_SUCCESS,
    stocks,
    products
  };
}

export function updateParams(name: string, value: string) {
  return {
    type: actionTypes.UPDATE_PARAMS,
    name,
    value
  };
}
export function resetTimeParam() {
  return {
    type: actionTypes.RESET_TIME_PARAM
  };
}

export const updateTransactionList = (transaction: ITransactionItem) => ({
  type: actionTypes.UPDATE_TRANSACTION_LIST,
  transaction
});

export const removeItemInTransactionList = (transaction: ITransactionItem) => ({
  type: actionTypes.REMOVE_ITEM_IN_TRANSACTION_LIST,
  transaction
});

export const filterTransactions = (transactionType: TransactionType) => ({
  type: actionTypes.FILTER_TRANSACTIONS,
  transactionType
});
