import { MobileDetectDevice } from '@src/utils/functionUtils';

const VIEWPORT_TOP_CONST = 60;

const getViewportHeight = () =>
  Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const offsetToDocument = (el: any) => {
  var rect = el.getBoundingClientRect();
  var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const offsetToParent = (el: any) => ({
  top: el.offsetTop,
  left: el.offsetLeft
});

function getQueryVariable(variable: string) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return '';
}

const isAndroid = () => {
  const mobile = new MobileDetectDevice().init();
  return !!(typeof mobile === 'object' && mobile.os.name === 'Android');
};

const handleScreenPosition = (inputRef: HTMLDivElement | null, shouldMoveElId: string) => {
  const modal = document.getElementById(shouldMoveElId);
  if (isAndroid() && inputRef && modal) {
    const refViewportTop = inputRef.getBoundingClientRect().top;
    const modalBottom = modal.getBoundingClientRect().bottom - window.innerHeight;
    const moveDownPx = VIEWPORT_TOP_CONST - refViewportTop + modalBottom;
    if (moveDownPx > 0) {
      modal.style.bottom = `-${moveDownPx}px`;
    }
  }
};

const resetScreenPosition = (shouldMoveElId: string) => {
  const modal = document.getElementById(shouldMoveElId);
  if (isAndroid() && modal) {
    modal.style.bottom = '0px';
  }
};

export { getViewportHeight, offsetToDocument, offsetToParent, getQueryVariable, handleScreenPosition, resetScreenPosition, isAndroid };
