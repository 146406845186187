import ReactGA from 'react-ga';
import getConfig from 'next/config';

import { IAnalyticEventArgs, IAnalyticKarteEventArgs, IAnalyticKarteEventTopArgs } from '@src/interfaces/IGoogleAnalytic';

const { publicRuntimeConfig = {} } = getConfig() || {};
const { GA_ID, GTM_ID } = publicRuntimeConfig;

export const GA_TRACKING_ID = `${GA_ID}`;
export const GTM_TRACKING_ID = `${GTM_ID}`;

export const initGA = () => {
  ReactGA.initialize(GA_TRACKING_ID);
};

export const logPageView = (disableTrackingKarte?: boolean) => {
  if (!disableTrackingKarte) {
    window.krt('send', 'view', { pathname: window.location.pathname });
  }

  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
  dataLayer.push({ event: 'after_customdimension_setup', pageview_path: window.location.pathname });
};

export const analyticsLogPageView = (path: string, trackerNames?: ReactGA.TrackerNames, title?: string) => {
  ReactGA.set({ page: path }, trackerNames);
  ReactGA.pageview(path, trackerNames, title);
  dataLayer.push({ event: 'virtual_pageview', virtual_pageview_path: path, virtual_pageview_title: title });
  window.krt('send', 'view', { title, pathname: path });
};

export const analyticsLogEvent = (eventArg: IAnalyticEventArgs) => {
  if (eventArg.karteAction) {
    const { category, karteAction } = eventArg;
    window.krt('send', category, { action: karteAction });
  }
  if (eventArg.category && eventArg.action) {
    ReactGA.event(eventArg);

    if (typeof trEventClick !== 'undefined') {
      trEventClick(this, eventArg.category, eventArg.action, eventArg.label || '');
    }
  }
};

export const analyticsLogKarteEvent = (eventArg: IAnalyticKarteEventArgs) => {
  const { karteCategory, karteField, karteValue } = eventArg;
  const valueObj: {[key: string]: string | number | Date} = {};
  valueObj[karteField] = karteValue;

  window.krt('send', karteCategory, valueObj);
};

export const analyticsLogKarteEventForTop = (category: string, eventArg: IAnalyticKarteEventTopArgs) => {
  window.krt('send', category, eventArg);
};

export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};

export const setTracker: typeof ReactGA.set = (fieldsObject, trackerNames) => {
  ReactGA.set(fieldsObject, trackerNames);
};
