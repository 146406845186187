import { IAppState } from '@src/interfaces/IAppState';

export const userStateSeletor = (appState: IAppState) => (
  appState.userState
);

export const userInfoSeletor = (appState: IAppState) => (
  appState.userState.userInfo
);

export const isFirstCrawlDoneSelector = (appState: IAppState) => (
  appState.userState.userInfo.is_first_crawl_done
);
