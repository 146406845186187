import * as Sentry from '@sentry/browser';
import getConfig from 'next/config';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getSentryScope(scope: any, ctx: any, err: any, fingerPrint?: string) {
  const { message, statusCode, request, config, response } = err;

  scope.setFingerprint([fingerPrint || message]);
  request && scope.setExtra('request', JSON.stringify({ response: request.response, responseURL: request.responseURL }));
  config && scope.setExtra('config', JSON.stringify({ params: config.params, url: config.url }));
  response && scope.setExtra('response', JSON.stringify(response.data));
  statusCode && scope.setExtra('statusCode', statusCode);

  if (ctx) {
    const { req, res, errorInfo, query = '', pathname = '' } = ctx;

    if (res && res.statusCode) {
      scope.setExtra('statusCode', res.statusCode);
    }

    if (typeof sessionStorage !== 'undefined') {
      const token = sessionStorage.getItem('token');
      if (token) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const decodedJwt: any = jwtDecode(token);
        scope.setUser({ id: decodedJwt.User.KpfmId });
      }
    }

    if (typeof window !== 'undefined') {
      scope.setTag('ssr', 'false');
      query && scope.setExtra('query', query);
      pathname && scope.setExtra('pathname', pathname);

      const sessionId = Cookie.get('sid');
      if (sessionId) {
        scope.setUser({ id: sessionId });
      }
    } else {
      if (req) {
        scope.setTag('ssr', 'true');
        scope.setExtra('url', req.url);
        scope.setExtra('method', req.method);
        scope.setExtra('headers', req.headers);
        scope.setExtra('params', req.params);
        scope.setExtra('query', req.query);

        if (req.cookies.sid) {
          scope.setUser({ id: req.cookies.sid });
        }
      }
    }

    if (errorInfo) {
      Object.keys(errorInfo).forEach(key =>
        scope.setExtra(key, errorInfo[key])
      );
    }
  }
  return scope;
}

export default () => {
  const release = process.env.SENTRY_RELEASE;
  const { publicRuntimeConfig = {} } = getConfig() || {};
  const { SENTRY_DSN, APP_ENV } = publicRuntimeConfig;
  const sentryOptions = {
    dsn: SENTRY_DSN,
    environment: APP_ENV,
    release,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    integrations: [new Sentry.Integrations.Breadcrumbs({
      console: false,
      dom: true,
      xhr: true,
      fetch: true,
      history: true
    })]
  };

  Sentry.init(sentryOptions);
  return {
    Sentry,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    captureException: (err: any, ctx: any) => {
      if (!SENTRY_DSN) return;
      const { message, statusCode } = err;
      const newErr = message ? new Error(`Error: ${message} ${statusCode ? ' ;statusCode: ' + statusCode : ''}`) : err;

      Sentry.configureScope(scope => {
        return getSentryScope(scope, ctx, err);
      });
      return Sentry.captureException(newErr);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    captureMessage: (err: any, ctx: any) => {
      const { title } = err;

      Sentry.configureScope(scope => {
        return getSentryScope(scope, ctx, err, title);
      });
      return Sentry.captureMessage(title);
    }
  };
};
