import { IReportState, ISettingInfo } from '@src/interfaces/IReportState';

export const actionTypes = {
  GET_REPORT_DATA: 'GET_REPORT_DATA',
  GET_REPORT_DATA_SUCCESS: 'GET_REPORT_DATA_SUCCESS',
  UPDATE_REPORT_SETTING_DATA: 'UPDATE_REPORT_SETTING_DATA',
  UPDATE_REPORT_TYPE: 'UPDATE_REPORT_TYPE',
  HANDLE_BACK_IN_REPORT_SETTING: 'HANDLE_BACK_IN_REPORT_SETTING',
};

export const getReportData = (reportType: string) => ({
  type: actionTypes.GET_REPORT_DATA,
  reportType
});

export const getReportDataSuccess = (data: IReportState) => ({
  type: actionTypes.GET_REPORT_DATA_SUCCESS,
  data
});

export const updateReportSettingData = (settingInfo: ISettingInfo) => ({
  type: actionTypes.UPDATE_REPORT_SETTING_DATA,
  settingInfo
});

export const updateReportType = (reportType: string) => ({
  type: actionTypes.UPDATE_REPORT_TYPE,
  reportType
});

export const handleBackInReportSetting = () => ({
  type: actionTypes.HANDLE_BACK_IN_REPORT_SETTING
});
