import moment from '@src/utils/kpfmMoment';
import { ICalendarMonthValue } from '@src/interfaces/ICalendarMonth';

export const MinimumYear = 1970;

export function getCurrentMonth() {
  const currentMonth = moment().month();
  return (currentMonth < 8) ? `0${currentMonth + 1}` : `${currentMonth + 1}`;
}
export function getCurrentYear() {
  return moment().year();
}

export function formatDate(formatYYMMN?: boolean) {
  const currentTime = moment();

  if (formatYYMMN) {
    const date = currentTime.format('YYYY-MM');
    return date;
  }
  const date = currentTime.format('YYYY-MM-DD');
  return date;
}

export function getAdjustedCalendarValue(calendarValue: ICalendarMonthValue, reduce: boolean = false): ICalendarMonthValue {
  const { month = { value: '01', label: '1月' }, currentYear } = calendarValue;

  const currentMonth = Number(month.value);
  const newMonth = reduce ? currentMonth - 1 : currentMonth + 1;

  let newCalendarValue = { month: newMonth, year: currentYear };
  newCalendarValue = newMonth === 13 ? { month: 1, year: +currentYear + 1 } : newCalendarValue;
  newCalendarValue = newMonth === 0 ? { month: 12, year: +currentYear - 1 } : newCalendarValue;

  return {
    currentYear: newCalendarValue.year,
    month: {
      value: (newCalendarValue.month < 10) ? `0${newCalendarValue.month}` : `${newCalendarValue.month}`,
      label: `${newCalendarValue.month}月`
    }
  };
}

export function isFutureTime(calendarValue: ICalendarMonthValue, currentTimeValue?: ICalendarMonthValue): boolean {
  try {
    const { currentYear: year, month = { value: getCurrentMonth() } } = calendarValue;

    const currentMonth = (currentTimeValue && currentTimeValue.month) ? currentTimeValue.month.value : getCurrentMonth();
    const currentYear = currentTimeValue ? currentTimeValue.currentYear : getCurrentYear();

    return (month.value > currentMonth && year === currentYear) || year > currentYear;
  } catch {
    return false;
  }
}

export function detectSysDatetime(toBeInvalidDate: string) {
  const expiredDateInfo = {
    expiredText: '',
    isExpiredLessAMonth: false,
    expiredDate: ''
  };
  const systemDatetime = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const invalidDate = moment(toBeInvalidDate, 'YYYYMMDD');

  if (!toBeInvalidDate) {
    expiredDateInfo.expiredText = '－';
  } else if (toBeInvalidDate === systemDatetime.format('YYYYMMDD')) {
    expiredDateInfo.expiredText = '本日23:59まで';
  } else if (invalidDate.diff(systemDatetime, 'month') >= 1 || invalidDate.diff(systemDatetime, 'month', true) < 0) {
    expiredDateInfo.expiredText = invalidDate.format('YYYY/MM/DD');
  } else {
    const diffDay = invalidDate.diff(systemDatetime, 'days');

    expiredDateInfo.expiredText = `あと${diffDay}日`;
    expiredDateInfo.isExpiredLessAMonth = true;
  }
  expiredDateInfo.expiredDate = toBeInvalidDate ? invalidDate.format('MM/DD') : '';

  return expiredDateInfo;
}

export function getTimeFromCalendarValue(calendarValue: ICalendarMonthValue) {
  const currentMonth = moment().format('MM');
  const defaulMonth = { value: currentMonth, label: `${currentMonth}月` };
  const { currentYear, month = defaulMonth } = calendarValue;

  return `${currentYear}-${month.value}`;
}

export function parseStringToCalendarValue(calendar: string) {
  const calendarMoment = moment(calendar, 'YYYY-MM');
  const monthValue = calendarMoment.format('MM');
  const yearValue = calendarMoment.format('YYYY');

  return {
    currentYear: +yearValue,
    month: { value: monthValue, label: `${monthValue}月` }
  };
}

export const getExpiredDate = (expriredDate: string) => {
  return moment(expriredDate, 'MM/DD');
};
