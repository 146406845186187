import { IBalanceAccountState, IPointInfo, IUserCondition } from '@src/interfaces/IBalanceState';
import { IBundleResponse } from '@src/interfaces/IBundle';
import { IMenuListData } from '@src/interfaces/IMenuListData';
import { ITotalByCategory } from '@src/interfaces/IReportState';
import { MAINTENANCE_STATUS } from './appContanst';
import getConfig from 'next/config';

export const initialPointInfo: IPointInfo = {
  toBeInvalidDate: '',
  toBeInvalidDatePayma: '',
  toBeInvalidPaymaPoint: 0,
  toBeInvalidPointTotal: 0,
  pontaLink: '',
  asset_name: '',
  expire_at: '',
  message: '',
  is_sub_account: false,
  main_account_id: '',
  valuation_loss: 0,
  response_code: 0
};

export const initialUserCondition: IUserCondition = {
  financialBundlePlanMember: -1,
  payCardHoldSts: -1,
  jibunBankAccountHoldSts: -1,
  financeDisPaysetSts: -1
};

export const initialMessageSetting: IBundleResponse = {
  admin_settings: {
    show_financial_packages: true
  },
  message_settings: {
    show_financial_packages: true
  }
};

export const initialBalanceAcc:IBalanceAccountState = {
  name: '',
  id: '',
  balance: '－',
  balance2: '－',
  debt: '',
  type: '',
  payment_date: '',
  date: '',
  payment_date2: '',
  data_source: 0,
  transactions: [],
  is_processing: false,
  is_error: false,
  last_succeeded_at: '',
  last_aggregated_at: '',
  linked_at: '',
  status: 0,
  aggregation_error_id: 0,
  create_date: '',
  service_logo: '',
  service_format: {
    alias: '',
    color: ''
  },
  crd_sbt: '',
  j_bank_info: {
    is_exist_au_man_connecting_tx: false
  },
  expire_at: '',
  maintenance_status: MAINTENANCE_STATUS.NO_MAINTENANCE
};

export const initialTotalCategory: ITotalByCategory = {
  total: '－',
  pointTotal: '－',
  bankTotal: '－',
  creditTotal: '－',
  emoneyTotal: '－',
  ecTotal: '－'
};

export const intitialKantanInfo = {
  user_type: '',
  available_amt: '－',
  charge_use_abds: '1',
  charge_use_able_bal_amt: '－',
  all_total_amt: '',
  kantan_kiyaku: {
    acptStat: '',
    payUseRulesVer: '',
    payUseRulesAcptVer: '',
    payUseRulesChg: '',
    crdtlmtContent: '',
    crdtlmtGrp: '',
    useLimitAmtSet: ''
  },
  is_exist_kantan_kiyaku: false,
  is_exist_kantan_limit: false,
  is_exist_kantan_charge: false
};

export const inittialLayoutError = {
  theme: '',
  exclamation_mark: false,
  showingPrice: false,
  create_date: '',
  has_arrow_icon: false,
  text: '',
  karteEvent: '',
  message: '',
  messageUrl: '',
  contactMsg: '',
  linkRedirect: '',
  campaignText: '',
  campaignTextUrl: '',
  gaContactText: '',
  contactTextUrl: '',
  karteID: ''
};

const { publicRuntimeConfig = {} } = getConfig() || {};
const { APP_DOMAIN } = publicRuntimeConfig;

export const ListItems: IMenuListData[] = [
  {
    icon: '/static/media/top.png',
    title: 'TOP',
    link: '/asset/'
  },
  {
    icon: '/static/media/rireki.png',
    title: '利用履歴',
    link: '/history/all/'
  },
  {
    icon: '/static/media/syushi.png',
    title: '資産・収支',
    link: '/report/'
  },
  {
    icon: '/static/media/rireki_settei.png',
    title: '表示設定',
    link: '/accounts/'
  },
  {
    icon: '/static/media/bundle-icon.svg',
    title: 'auマネ活プランの設定',
    link: '/bundle_settings/',
    isDisplayMenuItem: true
  },
  {
    icon: '/static/media/top1.png',
    title: '家計見直し相談',
    link: `${APP_DOMAIN}https://www.fp.au-financial.com/lp/family/008/?unified_store_cd=KDDI&hub_cd=OKN&shop_name=auPAY%E3%82%A2%E3%83%97%E3%83%AA%EF%BC%88%E3%81%8A%E9%87%91%E3%81%AE%E7%AE%A1%E7%90%86%EF%BC%89`,
    isExternalLink: true
  }
];
