import router from 'next/router';
import { ListItems } from '@src/utils/initialData';
import * as React from 'react';
import { MenuListItem } from '@src/components/MenuListItem';
import { getEnvLink, getPathHasLastSlash, redirectExternalUrl, redirectInternalUrl } from '@src/utils/functionUtils';
import { withLinkAccount } from '@src/hocs/withLinkAccount';
import { IUserInfo } from '@src/interfaces/IUserState';
import { menuListAnalyticsEvents } from '@src/analyticsEvents/menuListAnalyticsEvent';
import { Portal } from '@src/components/Portal';
import { ModalTermOfUseAustock } from '@src/settings/components/ModalTermOfUseAustock';

interface MenuListProps {
  isOpenMenu: boolean;
  closeMenu: () => void;
  query: {[key: string]: string};
  user: IUserInfo;
  isShowBundle: boolean;
}

interface MenuListState {
  closeMenu: boolean;
  isOpenPolicyAustock: boolean;
}

class MenuList extends React.PureComponent<MenuListProps, MenuListState> {
  constructor(props: MenuListProps) {
    super(props);

    this.state = {
      closeMenu: false,
      isOpenPolicyAustock: false
    };
  }

  handleCloseMenu = () => {
    const { isOpenMenu } = this.props;
    if (isOpenMenu) {
      this.props.closeMenu();

      menuListAnalyticsEvents.eventClickMenuListCloseMenu();
    }
  }

  handleGoToPage = (title: string, link: string, isExternalLink?: boolean) => {
    const pathname = getPathHasLastSlash(router.pathname);
    if (link && [pathname].includes(link)) {
      this.props.closeMenu();
      menuListAnalyticsEvents.eventClickMenuList(title);
    } if (isExternalLink) {
      redirectExternalUrl(link);
      menuListAnalyticsEvents.eventClickMenuList(title);
    } else {
      redirectInternalUrl(link);
      menuListAnalyticsEvents.eventClickMenuList(title);
    }
  }

  handleModalPolicyAustock = () => {
    this.setState((prevState) => ({
      isOpenPolicyAustock: !prevState.isOpenPolicyAustock
    }));
    menuListAnalyticsEvents.eventClickMenuList('API連携（auカブコム証券）サービスご利用規約');
  }

  render() {
    const { isOpenMenu, isShowBundle } = this.props;
    const termOfUseLink = getEnvLink('TERM_OF_USE_LINK');

    return (
      <React.Fragment>
        {isOpenMenu &&
          <div className='modal kpfm-modal is-demo menu-page-wrapper'>
            <div className="menu-list">
              <div className="menu-list-container">
                <div className="menu-top-wrapper">
                  <h3>メニュー</h3>
                  <div className="close-menu" onClick={this.handleCloseMenu}>
                    <img
                      src="/static/media/close.png"
                      alt="Close menu"
                    />
                  </div>
                </div>
                <ul className="menu-list-wrapper" id='list-item'>
                  {ListItems.map((listItem, index) => (
                    ((listItem.isDisplayMenuItem && isShowBundle) || !listItem.isDisplayMenuItem)
                      ? <MenuListItem
                        key={index}
                        title={listItem.title}
                        icon={listItem.icon}
                        onClick={() => this.handleGoToPage(listItem.title, listItem.link, listItem.isExternalLink)}
                      />
                      : <></>)
                  )}
                  <MenuListItem
                    title="よくあるご質問"
                    icon="/static/media/question.png"
                    onClick={() => this.handleGoToPage('よくあるご質問', '/faq/', false)}
                  />
                  <MenuListItem
                    title="API連携（auカブコム証券）サービスご利用規約"
                    icon="/static/media/KabucomTerm.png"
                    onClick={this.handleModalPolicyAustock}
                  />
                  <MenuListItem
                    title="kabu.com APIサービス利用規定"
                    icon="/static/media/KabucomTerm.png"
                    onClick={() => this.handleGoToPage('kabu.com APIサービス利用規定', termOfUseLink, true)}
                  />
                </ul>
                <Portal selectorElement="#portal-modal">
                  <ModalTermOfUseAustock
                    onCloseModal={this.handleModalPolicyAustock}
                    isOpenModal={this.state.isOpenPolicyAustock}
                  />
                </Portal>

              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default withLinkAccount(MenuList);
