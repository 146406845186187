import { analyticsLogEvent } from '@src/utils/analytics';

export const menuListAnalyticsEvents = {
  eventClickMenuList: (action: string) => analyticsLogEvent({
    category: '【click】メニュー',
    action
  }),
  eventClickMenuListAA: () => analyticsLogEvent({ category: '【click】メニュー', action: '連携追加' }),
  eventClickMenuListCloseMenu: () => analyticsLogEvent({ category: '【click】メニュー', action: '閉じる' })
};
