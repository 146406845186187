import React, { FC } from 'react';
import Router from 'next/router';
import { ModalConfig, connectModal, InjectedProps, hide } from 'redux-modal';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ButtonOutlined } from '@src/components/BaseButton';
import { MODAL_NAME, pathConstant } from '@src/utils/appContanst';

interface ModalDeletedStockUserUnusedProps extends InjectedProps {
  closeModal(): void;
}

const ModalDeletedStockUserUnused: FC<ModalDeletedStockUserUnusedProps> = ({ closeModal }: ModalDeletedStockUserUnusedProps) => {
  const handleCloseModal = () => {
    let url = pathConstant.topPath;

    if (sessionStorage) {
      const historyRoutes = JSON.parse(sessionStorage.getItem('historyRoutes') || '[]');
      url = historyRoutes.length ? historyRoutes.pop() : pathConstant.topPath;
      sessionStorage.setItem('historyRoutes', JSON.stringify(historyRoutes));
    }
    closeModal();
    Router.push(url);
  };

  return (
    <div className="modal kpfm-modal modal-household-term modal-household-term-2 is-demo">
      <div className="modal-background" />
      <div className="modal-content mobile-max-height">
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={handleCloseModal}
        />
        <div className="modal-head">通知</div>
        <div className="modal-bodycontent">
          <p>現在、メンテナンスのためサービスとの連携がご利用いただけません</p>
        </div>
        <div className="modal-bottom-content">
          <ButtonOutlined
            id="modal-policy"
            onClick={handleCloseModal}
            className="term-of-user__btn-policy btn-apply is-full font-size-normal"
          >
          閉じる
          </ButtonOutlined>
        </div>
      </div>
    </div>
  );
};

const modalConfig: ModalConfig = {
  name: MODAL_NAME.DELETED_STOCK_USER_UNUSED
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => dispatch(hide(MODAL_NAME.DELETED_STOCK_USER_UNUSED))
});

export default connectModal(modalConfig)(connect(null, mapDispatchToProps)(ModalDeletedStockUserUnused));
