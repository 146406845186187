/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Header from '@src/components/Header';
import { ERROR_RESPONSE_TYPES, isSuccessResponse } from '@src/utils/errorHandler';
import { IErrorMessage } from '@src/interfaces/ICommonState';
import { setErrorMessage } from '@src/actions/commonActions';

interface ErrorProps {
  errorResponseType?: ERROR_RESPONSE_TYPES.OTHERS_TYPES | ERROR_RESPONSE_TYPES.TOO_MANY_REQUESTS | ERROR_RESPONSE_TYPES.NOT_FOUND;
  statusCode: number;
  message: string;
  sentryMsg?: string;
  setErrorMessage(errorMessage: IErrorMessage): void;
}

const ErrorOtherTypes: React.FunctionComponent<{}> = () => {
  return (
    <React.Fragment>
      <Header title="エラーが発生しました。"/>
      <div className="page-500">
        <div className="page-500__container">
          <h3 className="title-500">メンテナンスのお知らせ</h3>
          <div className="page-500__content">
            現在、メンテナンス作業を実施しております。<br />
            お手数ですが、しばらく時間をおいて再度アクセスをお願いいたします。<br />
            <br/>
            <p style={{ color: '#333' }}>
              お客さまには、ご迷惑をおかけし誠に申し訳ございませんが、ご理解のほどよろしくお願いいたします。<br/>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ErrorManyRequests: React.FunctionComponent<{}> = () => {
  return (
    <React.Fragment>
      <Header title="現在アクセスが集中しています。"/>
      <div className="page-500">
        <div className="page-500__container">
          <h3 className="title-500">現在アクセスが集中しています。</h3>
          <div className="page-500__content">
            情報を表示できませんでした。<br />
            時間をおいて再度お試しください。<br />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

class Error extends React.Component<ErrorProps> {
  componentWillUnmount() {
    this.props.setErrorMessage({ title: '', message: '', statusCode: '' });
  }

  render() {
    const { statusCode, message, errorResponseType } = this.props;

    if (statusCode && !isSuccessResponse(statusCode)) {
      return (
        (errorResponseType === ERROR_RESPONSE_TYPES.TOO_MANY_REQUESTS)
          ? <ErrorManyRequests {...this.props}/>
          : (errorResponseType === ERROR_RESPONSE_TYPES.OTHERS_TYPES)
            ? <ErrorOtherTypes {...this.props}/>
            : (
              <React.Fragment>
                <Header title="エラー"/>
                <div className="page-500">
                  <div className="page-500__container">
                    <h3 className="title-500">エラー</h3>
                    <div className="page-500__content"><strong>{statusCode}</strong> {message}</div>
                  </div>
                </div>
              </React.Fragment>
            )
      );
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setErrorMessage: (errorMessage: IErrorMessage) => dispatch(setErrorMessage(errorMessage))
});

export default connect(null, mapDispatchToProps)(Error);
