import moment from '@src/utils/kpfmMoment';
import { getCurrentMonth, getCurrentYear } from '@src/utils/dateTime';

require('moment/locale/ja');
moment.locale('ja');

export const formatMoney = (number: string | number) => {
  if (!number) return '0';
  if (number === '-' || number === '－') return '－';

  return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const normalizeDate = (value: any) => {
  // get current time for converting more exactly
  const clock = moment().format('HH:mm:ss');
  value += ' ' + clock;
  return moment(value, 'YYYY-MM-DD HH:mm:ss');
};

export const formatYYMMDDddJapan = (datetimeString: string | number | undefined) => {
  const dateTime = normalizeDate(datetimeString);
  if (dateTime.isValid()) {
    return dateTime.format('LL (dd)');
  }
  return '';
};

export const formatDatetimeJapan = (datetime: string) => {
  let datetimeString = '';

  if (datetime) {
    const { year, month, day, time } = getTimeFromTimeServer(datetime.replace('Z', '+09:00'));

    datetimeString = moment(`${year}${month}${day}${time}`, 'YYYYMMDDhhmmss').format('YYYY年M月D日H時mm分');
  }

  return datetimeString;
};

export const formatDateJapan = (datetime: string) => {
  let dateString = '';

  if (datetime) {
    const { year, month, day } = getTimeFromTimeServer(datetime.replace('Z', '+09:00'));

    dateString = moment(`${year}${month}${day}`, 'YYYYMMDD').format('YYYY年M月D日');
  }

  return dateString;
};

export const formatYYMMDDJapan = (datetimeString: string | number | undefined) => {
  const dateTime = normalizeDate(datetimeString);
  if (dateTime.isValid()) {
    return dateTime.format('LL');
  }
  return '';
};

export const formatYYMMJapan = (datetimeString: string) => {
  if (!datetimeString) return `${getCurrentYear()}年${getCurrentMonth()}月`;

  const datetime = moment(datetimeString, 'YYYY-MM-DD');
  const month = datetime.format('MM');
  const year = datetime.format('YYYY');

  return `${year}年${month}月`;
};

export const formatMMDD = (datetimeString: string | number | undefined) => {
  const dateTime = normalizeDate(datetimeString);
  if (dateTime.isValid()) {
    return dateTime.format('MM/DD');
  }
  return '';
};

export const formatYYMMDD = (datetimeString: string | number | undefined) => {
  const dateTime = normalizeDate(datetimeString);
  if (dateTime.isValid()) {
    return dateTime.format('YYYY/MM/DD');
  }
  return '';
};

export const formatDay = (dateString: string) => {
  if (!dateString) return '';
  return moment(dateString, 'YYYYMMDD').format('DD');
};

export const getTimeFromTimeServer = (datetime: string) => {
  // eslint-disable-next-line no-useless-escape
  // year: 1, month: 2, day: 3
  const regex = /^([\d]{2,4})\-([\d]{1,2})\-([\d]{1,2})T(.*)\+(.*)$/;
  const parseTime = datetime ? datetime.match(regex) : '';
  const defaultTime = { year: '', month: '', day: '', time: '', zone: '' };
  const timeObj = parseTime && parseTime.length
    ? { year: parseTime[1], month: parseTime[2], day: parseTime[3], time: parseTime[4], zone: parseTime[5] }
    : defaultTime;

  return parseTime ? timeObj : defaultTime;
};

export const getDateFromTimeServer = (date: string) => {
  // eslint-disable-next-line no-useless-escape
  // year: 1, month: 2, day: 3
  const regex = /^([\d]{2,4})([\d]{1,2})([\d]{1,2})$/;
  const parseTime = date ? date.match(regex) : '';
  const defaultTime = { year: '', month: '', day: '' };
  const timeObj = parseTime && parseTime.length
    ? { year: parseTime[1], month: parseTime[2], day: parseTime[3] }
    : defaultTime;

  return parseTime ? timeObj : defaultTime;
};
