import * as React from 'react';
import { SingletonRouter, withRouter } from 'next/router';
import queryString from 'query-string';

interface WithRouterParsedQueryProps {
  router?: SingletonRouter;
  aggregationType?: string,
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const withRouterParsedQuery = (ComposedComp: any) => {
  class withRouterParsedQuery extends React.Component<WithRouterParsedQueryProps, any> {
    render() {
      const asPath = this.props.router && this.props.router.asPath ? this.props.router.asPath : '';
      const { query } = queryString.parseUrl(asPath);
      return <ComposedComp {...this.props} query={query} aggregationType={this.props.aggregationType}/>;
    }
  }

  return withRouter<any, any>(withRouterParsedQuery);
};
