import * as React from 'react';
import Link from 'next/link';
import { IButton } from '@src/interfaces/IButton';

interface KIButton extends IButton {
  hasArrow?: boolean;
  arrowOrange?: boolean;
}

export const ButtonOutlined: React.FunctionComponent<KIButton> = ({ className: passedClasses, hasArrow, arrowOrange, ...props }: KIButton) => {
  const className = `${props.href ? 'btn-flex' : ''} button is-kpfm is-outlined ${passedClasses || ''}`;

  if (props.href) {
    return (
      <Link href={props.href}>
        <a {...props} className={className}>
          {props.children}
          {hasArrow && <i className="list-small--arrow kicon-arrow-grey--right" />}
          {arrowOrange && <i className="list-small--arrow kicon-arrow-orange--right" />}
        </a>
      </Link>
    );
  };

  return (
    <button {...props} className={className}>
      <span>
        {props.children}
        {hasArrow && <i className="list-small--arrow kicon-arrow-grey" />}
        {arrowOrange && <i className="list-small--arrow kicon-arrow-orange--right" />}
      </span>
    </button>
  );
};
