import { IAnalyticKarteEventTopArgs } from '@src/interfaces/IGoogleAnalytic';
import { analyticsLogEvent, analyticsLogKarteEventForTop } from '@src/utils/analytics';

export const assetTopAnalyticsEvents = {
  eventClickAAService: (action: string) => analyticsLogEvent({
    category: '【click】4-1資産TOP',
    action
  }
  ),
  eventClickKarteForTop: (category:string, karteEvent: IAnalyticKarteEventTopArgs) => analyticsLogKarteEventForTop(category, karteEvent),
  eventClickMyKantan: (action: string) => analyticsLogEvent({ category: '【click】トップ画面', action }),
  eventDisplayKantan: (action: string) => analyticsLogEvent({ category: '【visible】トップ', action }),
  eventDisplayMsgOfNewAccounts: (category: string, action: string) => analyticsLogEvent({ category, action }),
  eventClickBundle: (category: string, action: string) => analyticsLogEvent({ category, action }),
  eventDisplayBundle: (category: string, action: string) => analyticsLogEvent({ category, action })
};
