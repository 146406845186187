import React from 'react';

interface MenuListItemsProps {
  icon: string;
  title: string;
  onClick?(): void;
}

// eslint-disable-next-line react/prop-types
export const MenuListItem: React.FunctionComponent<MenuListItemsProps> = ({ icon, title, onClick }) => {
  return (
    <li className="menu-list-item" onClick={onClick}>
      <div className="item-left">
        <div className="item-img">
          <img src={icon} alt={title} />
        </div>
        <div className="item-title">
          <p>{title}</p>
        </div>
      </div>
      <div className="item-right">
        <img
          src="/static/media/arrow.png"
          alt="arrow"
        />
      </div>
    </li>
  );
};
