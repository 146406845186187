import { AxiosError } from 'axios';
import Sentry from './sentry';

export enum ERROR_STATUS_CODES {
  TOO_MANY_REQUESTS = 429,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOW = 405,
  REQUEST_TIMEOUT = 408,
  LINKED_ACCOUNT = 409,
  SERVER_ERROR = 500,
  NOT_IMPLEMENT = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
};

export enum ERROR_RESPONSE_TYPES {
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  OTHERS_TYPES = 'OTHERS_TYPES',
  NOT_FOUND = 'NOT_FOUND',
  NONE = 'NONE',

};

export function isSuccessResponse(statusCode: number): boolean {
  if (+statusCode && +statusCode >= 200 && statusCode <= 299) return true;
  return false;
}

export function errorResponseType(statusCode: number | string): ERROR_RESPONSE_TYPES.OTHERS_TYPES | ERROR_RESPONSE_TYPES.TOO_MANY_REQUESTS | ERROR_RESPONSE_TYPES.NONE | ERROR_RESPONSE_TYPES.NOT_FOUND {
  if (statusCode && +statusCode > 0) {
    if (+statusCode && +statusCode === ERROR_STATUS_CODES.TOO_MANY_REQUESTS) return ERROR_RESPONSE_TYPES.TOO_MANY_REQUESTS;
    if (+statusCode && +statusCode === ERROR_STATUS_CODES.NOT_FOUND) return ERROR_RESPONSE_TYPES.NOT_FOUND;
    return ERROR_RESPONSE_TYPES.OTHERS_TYPES;
  }
  return ERROR_RESPONSE_TYPES.NONE;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function errorHandler(error: any) {
  const { captureException } = Sentry();
  const { response } = error;

  error.message = response ? response.data.message : error.message;
  error.statusCode = response ? response.status : '';

  // Two case that don't send to Sentry
  // 1: Net work error
  if (error.message.includes['Network Error']) {
    return;
  }

  // 2: status 401 Unauthorized
  if (error.statusCode === 401) {
    return;
  }

  captureException(error, {});
}

export function redirectToErrorPage(callbackError?: Function) {
  typeof callbackError === 'function' && callbackError();
}

export function getErrorResponseAxios(errorResponse: AxiosError) {
  let message = '';
  let code: number | string = '';
  if (errorResponse && errorResponse.response) {
    const { data, status } = errorResponse.response;
    if (data && data.message) {
      message = data.message;
    }
    code = status;
  }
  return {
    message,
    code
  };
}

export function getHyperlinkErrorMessage(errorMessage: string = '') {
  if (errorMessage.includes('※')) {
    return {
      errorMessage: errorMessage.replace('※', '※<u>') + '</u>',
      isHyperLink: true
    };
  }
  return { errorMessage };
}
