import { IBundleInfo, IScript } from '@src/interfaces/IBundle';
import {
  BUNDLE_CAMPAIGN_MSG,
  BUNDLE_CONTACT_MSG,
  BUNDLE_ELEMENT,
  BUNDLE_NEW_MONEYLIFE_CAMPAIGN_MSG,
  BUNDLE_NOTICE_TEXT
} from './appContanst';
import getConfig from 'next/config';

enum PayCardHoldStsEnum {
  UNKNOWN = -1,
  NOT_APPLIED = 0,
  APPLIED = 1
}

enum JibunBankAccountHoldStsEnum {
  UNKNOWN = -1,
  NOT_APPLIED = 0,
  APPLIED = 1
}

enum FinanceDisPaysetStsEnum {
  UNKNOWN = -1,
  NOT_APPLIED = 0,
  APPLIED = 1,
  APPLIED_CASE_2 = 2,
  APPLIED_CASE_3 = 3,
  APPLIED_CASE_4 = 4
}

const { publicRuntimeConfig = {} } = getConfig() || {};
const { BUNDLE_CAMPAIGN_MESSAGE_LINKED, BUNDLE_CAMPAIGN_MESSAGE_UNLINKED } =
  publicRuntimeConfig;
const campaignMsgForBundleLinked = BUNDLE_CAMPAIGN_MESSAGE_LINKED || '';
const campaignMsgForBundleUnlinked =
  BUNDLE_CAMPAIGN_MESSAGE_UNLINKED || '';

// Case financialBundlePlanMember = 0
export const getBundleNotAppliedMsg = (isAuPay: boolean) => {
  let result: IBundleInfo | undefined;
  if (isAuPay) {
    result = {
      textOrange: BUNDLE_NOTICE_TEXT.AUPAY_NOTLINKED_WITH_PLUS,
      campaignMsg: BUNDLE_NEW_MONEYLIFE_CAMPAIGN_MSG.PLAN_MEMBER_NOTAPPLIED,
      element: BUNDLE_ELEMENT.UNLINKED,
      url: campaignMsgForBundleUnlinked
    };
  }

  return result;
};

const getScriptValue = (
  script: IScript,
  payCardHoldSts: number,
  jibunBankAccountHoldSts: number,
  financeDisPaysetSts: number
) => {
  const statePayCardHold = script[payCardHoldSts];

  if (typeof statePayCardHold === 'string') {
    return statePayCardHold;
  } else {
    const statejibunBank = statePayCardHold[jibunBankAccountHoldSts];

    if (typeof statejibunBank === 'string') {
      return statejibunBank;
    } else {
      return statejibunBank[financeDisPaysetSts];
    }
  }
};

// Case financialBundlePlanMember = 2, 3
const financePlanMemberCaseTwoAndThreeScript: IScript = {
  [PayCardHoldStsEnum.UNKNOWN]: 'getUnknownMsg',
  [PayCardHoldStsEnum.NOT_APPLIED]: {
    [JibunBankAccountHoldStsEnum.UNKNOWN]: 'getUnknownMsg',
    [JibunBankAccountHoldStsEnum.NOT_APPLIED]:
      'getPayCardNotAppliedJbankNotAppliedMsg',
    [JibunBankAccountHoldStsEnum.APPLIED]:
      'getPayCardNotAppliedJbankAppliedMsg'
  },
  [PayCardHoldStsEnum.APPLIED]: {
    [JibunBankAccountHoldStsEnum.UNKNOWN]: 'getUnknownMsg',
    [JibunBankAccountHoldStsEnum.NOT_APPLIED]:
      'getPayCardAppliedJbankNotAppliedMsg',
    [JibunBankAccountHoldStsEnum.APPLIED]: {
      [FinanceDisPaysetStsEnum.UNKNOWN]: 'getUnknownMsg',
      [FinanceDisPaysetStsEnum.NOT_APPLIED]: 'getDisPaysetNotAppliedMsg',
      [FinanceDisPaysetStsEnum.APPLIED]: 'getDisPaysetAppliedMsg',
      [FinanceDisPaysetStsEnum.APPLIED_CASE_2]: 'getDisPaysetAppliedMsg',
      [FinanceDisPaysetStsEnum.APPLIED_CASE_3]: 'getDisPaysetAppliedMsg',
      [FinanceDisPaysetStsEnum.APPLIED_CASE_4]: 'getDisPaysetAppliedMsg'
    }
  }
};

const moduleFinancePlanMemberCaseTwo = (
  isAuPay: boolean,
  isCreditCard: boolean,
  isJBank: boolean
) => {
  return {
    getUnknownMsg: (): IBundleInfo | undefined => {
      let result: IBundleInfo | undefined;

      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
          bundleStatus: BUNDLE_CONTACT_MSG.APPLIED,
          element: BUNDLE_ELEMENT.LINKED,
          url: campaignMsgForBundleLinked
        };
      }
      return result;
    },

    getPayCardNotAppliedJbankNotAppliedMsg: () => {
      let result: IBundleInfo | undefined;
      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
          bundleStatus: BUNDLE_CONTACT_MSG.APPLIED,
          element: BUNDLE_ELEMENT.LINKED,
          url: campaignMsgForBundleLinked
        };
      }
      if (isJBank) {
        result = {
          campaignMsg: BUNDLE_CAMPAIGN_MSG.JBANK_UNLINKED,
          element: BUNDLE_ELEMENT.UNLINKED,
          url: campaignMsgForBundleLinked
        };
      }
      if (isCreditCard) {
        result = {
          campaignMsg: BUNDLE_CAMPAIGN_MSG.PAYCARD_NOTAPPLIED,
          element: BUNDLE_ELEMENT.UNLINKED,
          url: campaignMsgForBundleLinked
        };
      }

      return result;
    },

    getPayCardNotAppliedJbankAppliedMsg: (): IBundleInfo | undefined => {
      let result: IBundleInfo | undefined;
      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
          bundleStatus: BUNDLE_CONTACT_MSG.APPLIED,
          element: BUNDLE_ELEMENT.LINKED,
          url: campaignMsgForBundleLinked
        };
      }
      if (isCreditCard) {
        result = {
          campaignMsg: BUNDLE_CAMPAIGN_MSG.PAYCARD_NOTAPPLIED,
          url: campaignMsgForBundleLinked
        };
      }

      return result;
    },

    getPayCardAppliedJbankNotAppliedMsg: () => {
      let result: IBundleInfo | undefined;
      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
          bundleStatus: BUNDLE_CONTACT_MSG.APPLIED,
          element: BUNDLE_ELEMENT.LINKED,
          url: campaignMsgForBundleLinked
        };
      }
      if (isJBank) {
        result = {
          campaignMsg: BUNDLE_CAMPAIGN_MSG.JBANK_UNLINKED,
          url: campaignMsgForBundleLinked
        };
      }

      return result;
    },

    getDisPaysetNotAppliedMsg: () => {
      let result: IBundleInfo | undefined;
      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
          element: BUNDLE_ELEMENT.LINKED,
          campaignMsg: BUNDLE_CAMPAIGN_MSG.AUPAY_NOTAPPLIED,
          url: campaignMsgForBundleLinked
        };
      }
      return result;
    },

    getDisPaysetAppliedMsg: () => {
      let result: IBundleInfo | undefined;
      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
          bundleStatus: BUNDLE_CONTACT_MSG.APPLIED,
          element: BUNDLE_ELEMENT.LINKED,
          url: campaignMsgForBundleLinked
        };
      }
      return result;
    }
  };
};

export const getFinancialPlanMemberCaseTwoMsg = (
  payCardHoldSts: number,
  jibunBankAccountHoldSts: number,
  financeDisPaysetSts: number,
  isAuPay: boolean,
  isCreditCard: boolean,
  isJBank: boolean
) => {
  const financialPlanMemberFunctions = moduleFinancePlanMemberCaseTwo(
    isAuPay,
    isCreditCard,
    isJBank
  );
  const functionName = getScriptValue(
    financePlanMemberCaseTwoAndThreeScript,
    payCardHoldSts,
    jibunBankAccountHoldSts,
    financeDisPaysetSts
  );

  return financialPlanMemberFunctions[functionName]
    ? financialPlanMemberFunctions[functionName]()
    : undefined;
};

const moduleFinancePlanMemberCaseThree = (
  isAuPay: boolean,
  isCreditCard: boolean,
  isJBank: boolean
) => {
  return {
    getUnknownMsg: (): IBundleInfo | undefined => {
      let result: IBundleInfo | undefined;

      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED_WITH_PLUS,
          bundleStatus: BUNDLE_CONTACT_MSG.NEW_MONEY_LIFE,
          element: BUNDLE_ELEMENT.LINKED,
          url: campaignMsgForBundleLinked
        };
      }
      return result;
    },

    getPayCardNotAppliedJbankNotAppliedMsg: () => {
      let result: IBundleInfo | undefined;
      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED_WITH_PLUS,
          bundleStatus: BUNDLE_CONTACT_MSG.NEW_MONEY_LIFE,
          element: BUNDLE_ELEMENT.LINKED,
          url: campaignMsgForBundleLinked
        };
      }
      if (isJBank) {
        result = {
          campaignMsg: BUNDLE_NEW_MONEYLIFE_CAMPAIGN_MSG.JBANK_NOTAPPLIED,
          element: BUNDLE_ELEMENT.UNLINKED,
          url: campaignMsgForBundleLinked
        };
      }
      if (isCreditCard) {
        result = {
          campaignMsg: BUNDLE_NEW_MONEYLIFE_CAMPAIGN_MSG.PAYCARD_NOTAPPLIED,
          element: BUNDLE_ELEMENT.UNLINKED,
          url: campaignMsgForBundleLinked
        };
      }

      return result;
    },

    getPayCardNotAppliedJbankAppliedMsg: (): IBundleInfo | undefined => {
      let result: IBundleInfo | undefined;
      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED_WITH_PLUS,
          bundleStatus: BUNDLE_CONTACT_MSG.NEW_MONEY_LIFE,
          element: BUNDLE_ELEMENT.LINKED,
          url: campaignMsgForBundleLinked
        };
      }
      if (isCreditCard) {
        result = {
          campaignMsg: BUNDLE_NEW_MONEYLIFE_CAMPAIGN_MSG.PAYCARD_NOTAPPLIED,
          url: campaignMsgForBundleLinked
        };
      }

      return result;
    },

    getPayCardAppliedJbankNotAppliedMsg: () => {
      let result: IBundleInfo | undefined;
      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED_WITH_PLUS,
          bundleStatus: BUNDLE_CONTACT_MSG.NEW_MONEY_LIFE,
          element: BUNDLE_ELEMENT.LINKED,
          url: campaignMsgForBundleLinked
        };
      }
      if (isJBank) {
        result = {
          campaignMsg: BUNDLE_NEW_MONEYLIFE_CAMPAIGN_MSG.JBANK_NOTAPPLIED,
          url: campaignMsgForBundleLinked
        };
      }

      return result;
    },

    getDisPaysetNotAppliedMsg: () => {
      let result: IBundleInfo | undefined;
      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED_WITH_PLUS,
          element: BUNDLE_ELEMENT.LINKED,
          campaignMsg: BUNDLE_NEW_MONEYLIFE_CAMPAIGN_MSG.DIS_PAYSET_NOTAPPLIED,
          url: campaignMsgForBundleLinked
        };
      }
      return result;
    },

    getDisPaysetAppliedMsg: () => {
      let result: IBundleInfo | undefined;
      if (isAuPay) {
        result = {
          textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED_WITH_PLUS,
          bundleStatus: BUNDLE_CONTACT_MSG.NEW_MONEY_LIFE,
          element: BUNDLE_ELEMENT.LINKED,
          url: campaignMsgForBundleLinked
        };
      }
      return result;
    }
  };
};

export const getFinancialPlanMemberCaseThreeMsg = (
  payCardHoldSts: number,
  jibunBankAccountHoldSts: number,
  financeDisPaysetSts: number,
  isAuPay: boolean,
  isCreditCard: boolean,
  isJBank: boolean
) => {
  const financialPlanMemberFunctions = moduleFinancePlanMemberCaseThree(
    isAuPay,
    isCreditCard,
    isJBank
  );
  const functionName = getScriptValue(
    financePlanMemberCaseTwoAndThreeScript,
    payCardHoldSts,
    jibunBankAccountHoldSts,
    financeDisPaysetSts
  );

  return financialPlanMemberFunctions[functionName]
    ? financialPlanMemberFunctions[functionName]()
    : undefined;
};
