import { withRouterParsedQuery } from '@src/hocs/withRouterParsedQuery';
import { getAndRemovePrevRoute, getPathHasLastSlash } from '@src/utils/functionUtils';
import React from 'react';
import Router, { SingletonRouter } from 'next/router';
import { show } from 'redux-modal';
import { handleBackInReportSetting } from '@src/actions/reportActions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '@src/interfaces/IAppState';

interface BackButtonProps {
  isEditing: boolean;
  query: {[key: string]: string};
  router: SingletonRouter;
  showModalConfirmCancelEdit: () => void;
  handleBackInReportSetting: () => void;
};

class BackButton extends React.PureComponent<BackButtonProps> {
  constructor(props: BackButtonProps) {
    super(props);
  }

  handleBack = () => {
    const {
      isEditing, query, router,
      showModalConfirmCancelEdit,
      handleBackInReportSetting
    } = this.props;
    const pathname = getPathHasLastSlash(router.pathname);
    const isNeedToWaitReportSetting = ['/report/setting/'].includes(pathname);
    // incase user is opening Category list from transaction list, click back => back prev router
    // incase user is editing transaction detail, click back => show confirm cancel editing modal
    if (isEditing && query.modal_category_status !== 'open') {
      showModalConfirmCancelEdit();
    } else if (!isNeedToWaitReportSetting) {
      Router.push(getAndRemovePrevRoute());
    } else if (isNeedToWaitReportSetting) {
      handleBackInReportSetting();
    }
  }

  render() {
    return (
      <div className='back-button' id='back-icon' onClick={this.handleBack}>
        <div>
          <span className='back-icon'>{'<'}</span>
          戻る
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  isEditing: state.commonState.isEditing
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showModalConfirmCancelEdit: () => dispatch(show('CONFIRM_CANCEL_EDIT_MODAL')),
  handleBackInReportSetting: () => dispatch(handleBackInReportSetting())
});

export default withRouterParsedQuery(connect(mapStateToProps, mapDispatchToProps)(BackButton));
