import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ModalConfig, connectModal, InjectedProps, hide } from 'redux-modal';
import ReactSwipe from 'react-swipe';

import { ButtonOrange, ButtonOutlined } from '@src/components/BaseButton';
import { MODAL_NAME } from '@src/utils/appContanst';
import { withLinkAccount } from '@src/hocs/withLinkAccount';

interface ModalTutorialProps extends InjectedProps {
  closeModal(): void;
  gotoTermOfUseLink(): void;
  handleCloseModalTutorial(): void;
}

interface ModalTutorialStates {
  slideActive: number;
}

class ModalTutorial extends React.Component<ModalTutorialProps, ModalTutorialStates> {
  reactSwipeEl: any;
  constructor(props: ModalTutorialProps) {
    super(props);

    this.state = {
      slideActive: 0
    };
  }

  onSwipeChange = (index: number, elem: HTMLElement) => {
    this.setState({ slideActive: index });
  }

  render() {
    const { handleCloseModalTutorial, gotoTermOfUseLink } = this.props;
    const { slideActive } = this.state;

    return (
      <div className={'modal kpfm-modal modal-household-term modal-advice modal-tutorial is-demo'}>
        <div className="modal-background-success" />
        <div className="modal-aggregated-success">
          <div className="modal-content mobile-max-height modal-tutorial-content ">
            <div className="close-modal-tutorial" onClick={handleCloseModalTutorial}>
              <img className="img-close" src={require('/static/media/close.png')}></img>
            </div>
            <ReactSwipe
              className="carousel"
              swipeOptions={{ continuous: false, startSlide: slideActive, transitionEnd: this.onSwipeChange }}
              ref={(el: ReactSwipe) => (this.reactSwipeEl = el)}
            >
              <div className="modal-aggregated-success__content-img">
                <p className='modal-tutorial-header'>auのお金を管理しよう</p>
                <p className='modal-tutorial-description'>au PAY へのチャージの流れや、au PAY カードのご利用額確認など、auのお金を管理して使いすぎの抑止などに役立てましょう。</p>
                <img className='modal-tutorial-image' src={require('/static/media/tutorial_1.png')} />
                <ButtonOutlined onClick={() => this.reactSwipeEl.slide(1, 350)} className="modal-tutorial-btn has-arrow-icon">
                  さらに便利な使い方
                </ButtonOutlined>
              </div>
              <div className="modal-aggregated-success__content-img">
                <p className='modal-tutorial-header'>すべてのお金を管理しよう</p>
                <p className='modal-tutorial-description'>普段お使いのポイントや銀行、電子マネーなどが登録できます。まずはよく使うポイントから登録して管理に役立ててみましょう。</p>
                <img className='modal-tutorial-image img-tutorial-2' src={require('/static/media/tutorial_2.svg')} />
                <p className='modal-tutorial-note'>登録できるポイントは<span className='text-orange'>220</span> 種類</p>
                <ButtonOrange
                  onClick={gotoTermOfUseLink}
                  className="modal-tutorial-btn"
                >
                  はじめる
                </ButtonOrange>
              </div>
            </ReactSwipe>
            <div className='swipe-pagination'>
              <div className={`circle ${slideActive === 0 ? 'active' : 0}`}/>
              <div className={`circle ${slideActive === 1 ? 'active' : 0}`}/>
            </div>
            <div className="modal-bottom-content">
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const modalConfig: ModalConfig = { name: MODAL_NAME.TUTORIAL };
const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => dispatch(hide(MODAL_NAME.TUTORIAL))
});

export default connectModal(modalConfig)(connect(null, mapDispatchToProps)(withLinkAccount(ModalTutorial)));
