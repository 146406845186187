import { IBalanceState, IBalanceAccountState } from '@src/interfaces/IBalanceState';
import { IBundleResponse, IBundleSettingsCompact } from '@src/interfaces/IBundle';
import { ICalendarMonthValue } from '@src/interfaces/ICalendarMonth';
import { ITransactionChartData } from '@src/interfaces/ITransactionState';
import { TYPE_FETCH_CHART } from '@src/utils/appContanst';

export const actionTypes = {
  GET_BALANCE_INFO: 'GET_BALANCE_INFO',
  GET_BALANCE_INFO_SUCCESS: 'GET_BALANCE_INFO_SUCCESS',
  GET_BALANCE_DETAIL: 'GET_BALANCE_DETAIL',
  GET_BALANCE_DETAIL_SUCCESS: 'GET_BALANCE_DETAIL_SUCCESS',
  UPDATE_MONTH_SELECT: 'UPDATE_MONTH_SELECT',
  GET_TRANSACTIONS_CHART: 'GET_TRANSACTIONS_CHART',
  GET_TRANSACTIONS_CHART_SUCCESS: 'GET_TRANSACTIONS_CHART_SUCCESS',
  FETCH_MORE_TRANSACTIONS_CHART: 'FETCH_MORE_TRANSACTIONS_CHART',
  FETCH_MORE_TRANSACTIONS_CHART_SUCCESS: 'FETCH_MORE_TRANSACTIONS_CHART_SUCCESS',
  SAVE_SCROLL_LEFT_BEFORE: 'SAVE_SCROLL_LEFT_BEFORE',
  GO_TO_AU_PAY_DETAIL: 'GO_TO_AU_PAY_DETAIL',
  REMOVE_UNUSED_ACCOUNT_IN_STORE: 'REMOVE_UNUSED_ACCOUNT_IN_STORE',
  UPDATE_ACC_SHOW_CAMPAIGN_TEXT: 'UPDATE_ACC_SHOW_CAMPAIGN_TEXT',
  SET_AU_LOADING: 'SET_AU_LOADING',
  SET_STOCK_LOADING: 'SET_STOCK_LOADING',
  GET_AU_AND_STOCK_BANLANCE_INFO: 'GET_AU_AND_STOCK_BANLANCE_INFO',
  GET_AU_AND_STOCK_BANLANCE_INFO_SUCCESS: 'GET_AU_AND_STOCK_BANLANCE_INFO_SUCCESS',
  UPDATE_BUNDLE_SETTINGS: 'UPDATE_BUNDLE_SETTINGS',
  GET_BUNDLE_INFO: 'GET_BUNDLE_INFO',
  GET_BUNDLE_INFO_SUCCESS: 'GET_BUNDLE_INFO_SUCCESS'
};

export function getBundleInfo() {
  return {
    type: actionTypes.GET_BUNDLE_INFO
  };
}

export function getBundleSettingsSuccess(bundleInfo: IBundleResponse) {
  return {
    type: actionTypes.GET_BUNDLE_INFO_SUCCESS,
    bundleInfo
  };
}

export function sendBundleSettings(bundleSettings: IBundleSettingsCompact) {
  return {
    type: actionTypes.UPDATE_BUNDLE_SETTINGS,
    message_settings: bundleSettings
  };
}

export function getBalanceInfoSuccess(balance: IBalanceState) {
  return {
    type: actionTypes.GET_BALANCE_INFO_SUCCESS,
    balance
  };
}

export function getBalanceDetail(id: string, datetime: string, chartFrom?: string, chartTo?: string) {
  return {
    type: actionTypes.GET_BALANCE_DETAIL,
    id,
    datetime,
    chartFrom,
    chartTo
  };
}

export function getBalanceDetailSuccess(balanceAccount: IBalanceAccountState, datetime: string) {
  return {
    type: actionTypes.GET_BALANCE_DETAIL_SUCCESS,
    balanceAccount,
    datetime
  };
}

export function updateMonthSelect(monthSelect: ICalendarMonthValue) {
  return {
    type: actionTypes.UPDATE_MONTH_SELECT,
    monthSelect
  };
}

export function getTransactionsChart(account_ids: string, from: string, to: string, dataSource: number, mainAccountID: string) {
  return {
    type: actionTypes.GET_TRANSACTIONS_CHART,
    account_ids,
    from,
    to,
    dataSource,
    mainAccountID
  };
}

export function getTransactionsChartSuccess(data: ITransactionChartData) {
  return {
    type: actionTypes.GET_TRANSACTIONS_CHART_SUCCESS,
    transactionsChart: data.values || []
  };
}

export function fetchMoreTransactionsChart(account_ids: string, from: string, to: string, typeFetchChart: TYPE_FETCH_CHART) {
  return {
    type: actionTypes.FETCH_MORE_TRANSACTIONS_CHART,
    account_ids,
    from,
    to,
    typeFetchChart
  };
}

export function fetchMoreTransactionsChartSuccess(data: ITransactionChartData, typeFetchChart: TYPE_FETCH_CHART) {
  return {
    type: actionTypes.FETCH_MORE_TRANSACTIONS_CHART_SUCCESS,
    transactionsChart: data.values || [],
    typeFetchChart
  };
}

export function saveScrollLeftBefore(scrollLeft: number) {
  return {
    type: actionTypes.SAVE_SCROLL_LEFT_BEFORE,
    scrollLeft
  };
}

export function removeUnusedAccountInStore(id: string) {
  return {
    type: actionTypes.REMOVE_UNUSED_ACCOUNT_IN_STORE,
    id
  };
}

export function updateAccShowCampaignText(accountType: string) {
  return {
    type: actionTypes.UPDATE_ACC_SHOW_CAMPAIGN_TEXT,
    accountType
  };
};

export function setAuLoading(isLoading: boolean) {
  return {
    type: actionTypes.SET_AU_LOADING,
    isLoading
  };
}

export function setStockLoading(isLoading: boolean) {
  return {
    type: actionTypes.SET_STOCK_LOADING,
    isLoading
  };
}

export function getAuAndStockBalanceInfo(useLoadingModal: boolean) {
  return {
    type: actionTypes.GET_AU_AND_STOCK_BANLANCE_INFO,
    useLoadingModal
  };
}

export function getAuAndStockBalanceInfoSuccess(balanceAccount: IBalanceAccountState, isAuResponse: boolean) {
  return {
    type: actionTypes.GET_AU_AND_STOCK_BANLANCE_INFO_SUCCESS,
    balanceAccount,
    isAuResponse
  };
}
