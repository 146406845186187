import { ISettingInfo } from '@src/interfaces/IReportState';
import { IUserInfo } from '@src/interfaces/IUserState';

export const actionTypes = {
  LOGIN: 'LOGIN',
  LOAD_USER_INFO: 'LOAD_USER_INFO',
  LOAD_USER_INFO_SUCCESS: 'LOAD_USER_INFO_SUCCESS',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  UPDATE_USER: 'UPDATE_USER',
  USER_UPDATE_ACCEPT_POLICY: 'USER_UPDATE_ACCEPT_POLICY',
  USER_UPDATE_ACCEPT_POLICY_SUCCESS: 'USER_UPDATE_ACCEPT_POLICY_SUCCESS',
  UPDATE_SHOW_TUTORIAL_AGGREGATION: 'UPDATE_SHOW_TUTORIAL_AGGREGATION',
  UPDATE_SHOW_TUTORIAL_AGGREGATION_SUCCESS: 'UPDATE_SHOW_TUTORIAL_AGGREGATION_SUCCESS',
  UPDATE_SHOW_HISTORY_TOOLTIP_SUCCESS: 'UPDATE_SHOW_HISTORY_TOOLTIP_SUCCESS',
  LOAD_USER_ASSET_INFO: 'LOAD_USER_ASSET_INFO',
  UPDATE_STATE_REPORT_SETTING: 'UPDATE_STATE_REPORT_SETTING'
};

export function login(callback?: Function) {
  return {
    type: actionTypes.LOGIN,
    data: {
      callback
    }
  };
}

export function loadUserInfo(useLoadingModal: boolean = true) {
  return {
    type: actionTypes.LOAD_USER_INFO,
    useLoadingModal
  };
}

export function loadUserInfoSucess(user: IUserInfo) {
  return {
    type: actionTypes.LOAD_USER_INFO_SUCCESS,
    user
  };
}
export function updateUserInfo(user: IUserInfo) {
  return {
    type: actionTypes.UPDATE_USER_INFO,
    user
  };
}

export function updateShowTutorialAggregation() {
  return {
    type: actionTypes.UPDATE_SHOW_TUTORIAL_AGGREGATION
  };
}

export function updateShowTutorialAggregationSuccess() {
  return {
    type: actionTypes.UPDATE_SHOW_TUTORIAL_AGGREGATION_SUCCESS
  };
}

export function updateShowHistoryToolTipSuccess() {
  return {
    type: actionTypes.UPDATE_SHOW_HISTORY_TOOLTIP_SUCCESS
  };
}

export function updateUser() {
  return {
    type: actionTypes.UPDATE_USER
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function userUpdateAcceptPolicy(agree?: any, redirectLink?: any) {
  return {
    type: actionTypes.USER_UPDATE_ACCEPT_POLICY,
    agree,
    redirectLink
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function userUpdateAcceptPolicySuccess(agree: any) {
  return {
    type: actionTypes.USER_UPDATE_ACCEPT_POLICY_SUCCESS,
    agree
  };
}
export function loadUserAssetInfo() {
  return {
    type: actionTypes.LOAD_USER_ASSET_INFO
  };
}

export function updateStateReportSetting(settingInfo: ISettingInfo) {
  return {
    type: actionTypes.UPDATE_STATE_REPORT_SETTING,
    settingInfo
  };
}
