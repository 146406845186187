import * as React from 'react';
import AuLoadingIndicator from './au_load_indicator.png';
import AuLoadingLogo from './au_load_indicator_logo.png';

const LoadingSize = '42px';

const LoadingContainer: React.CSSProperties = {
  height: '100%',
  position: 'fixed',
  overflow: 'hidden',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 9999,
  background: 'rgba(255,255,255,0.7)',
  textAlign: 'center'
};

const LoadingContainerWhite: React.CSSProperties = {
  background: '#fff'
};

const LoadingRing: React.CSSProperties = {
  display: 'block',
  position: 'relative',
  width: '55px',
  height: '55px',
  top: '50%',
  transform: 'translatey(-50%)',
  WebkitTransform: 'translatey(-50%)',
  margin: '0 auto'
};

const LoadingIcon: React.CSSProperties = {
  position: 'absolute',
  fontStyle: 'italic',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: '-webkit-flex',
  WebkitAlignItems: 'center',
  alignItems: 'center',
  WebkitJustifyContent: 'center',
  justifyContent: 'center',
  width: `${LoadingSize}`,
  height: `${LoadingSize}`,
  backgroundImage: `url(${AuLoadingIndicator})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: `${LoadingSize} ${LoadingSize}`,
  backgroundPosition: 'center center',
  animation: 'rotation 2s infinite linear',
  WebkitAnimation: 'rotation 2s infinite linear'
};

const LoadingLogo: React.CSSProperties = {
  position: 'absolute',
  fontStyle: 'italic',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: '-webkit-flex',
  WebkitAlignItems: 'center',
  alignItems: 'center',
  WebkitJustifyContent: 'center',
  justifyContent: 'center',
  width: `${LoadingSize}`,
  height: `${LoadingSize}`,
  backgroundImage: `url(${AuLoadingLogo})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: `${LoadingSize} ${LoadingSize}`,
  backgroundPosition: 'center center'
};

export const Spiner: React.FunctionComponent<{}> = () => {
  return (
    <div style={LoadingContainer}>
      <div style={LoadingRing}>
        <div style={LoadingIcon}/>
        <div style={LoadingLogo}/>
      </div>
    </div>
  );
};
export const FullWhiteSpiner: React.FunctionComponent<{}> = () => {
  return (
    <div style={Object.assign(LoadingContainer, LoadingContainerWhite)}>
      <div style={LoadingRing}>
        <div style={LoadingIcon}/>
        <div style={LoadingLogo}/>
      </div>
    </div>
  );
};

export const LoadingCircle: React.FunctionComponent<{}> = () => (
  <div style={LoadingRing}>
    <div style={LoadingIcon}/>
    <div style={LoadingLogo}/>
  </div>
);
