import Head from 'next/head';
import * as React from 'react';
import getConfig from 'next/config';
import { isAuLoadingSelector } from '@src/selectors/balanceSelector';
import { IAppState } from '@src/interfaces/IAppState';
import { connect } from 'react-redux';

const { publicRuntimeConfig = {} } = getConfig() || {};
const { KARTE_CDN_URL } = publicRuntimeConfig;
export const karteUrl = KARTE_CDN_URL || '';

interface HeaderProps {
  title?: string;
  disableKarteScript?: boolean;
  isAuLoading?: boolean;
  isTopAsset?: boolean;
}

class Header extends React.Component<HeaderProps> {
  constructor(props: HeaderProps) {
    super(props);
  }

  render() {
    const { title, disableKarteScript, isAuLoading, isTopAsset } = this.props;
    const isReadyWindow = typeof window !== 'undefined';
    const isLoadingDone = !isAuLoading;
    const karteLink = isTopAsset ? (isLoadingDone ? karteUrl : '') : karteUrl;

    return (
      <Head>
        <title>{title}</title>
        {!disableKarteScript && isReadyWindow && <script async src={karteLink} > </script>}
      </Head>
    );
  }
};

const mapStateToProps = (state: IAppState) => ({
  isAuLoading: isAuLoadingSelector(state)
});

export default connect(mapStateToProps, null)(Header);
