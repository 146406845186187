export const kabucomTermTitle = 'API連携（auカブコム証券）サービスご利用規約';

export const kabucomTermContent = ` <div>
<h3><b>第1条（定義）</b></h3>
<br/>
<p>本規約において、以下の用語は、以下の定義する意味を有するものとします。</p>
<ul class='child'>
  <li><span>「アクセストークン」とは、お客さまからの申し出によりauカブコム証券株式会社（以下「auカブコム証券」という。）がKDDI株式会社及びauペイメント株式会社（併せて以下「両社」という。）に対して発行し、両社がauカブコム証券のシステムにアクセスするために必要となるトークン（発行済みのトークンの有効期限が経過した場合において、再度お客さまの認証を経ることなく、auカブコム証券が両社に対して発行したトークンを含みます。）をいいます。</span></li>
  <li><span>「アグリゲーションサービス」とは、お客さまの委託を受けて、電子情報処理組織を使用する方法により、両社がauカブコム証券からお客さまに関する情報（証券口座における取引情報や残高情報等を含みます。）およびauカブコム証券が保有する金融商品情報その他の公開情報のうち両社が指定する情報を取得し、これを本アプリ画面上で表示する方法でお客さまに提供するサービスです。</span></li>
  <li><span>「本アプリ」とは、両社所定のスマートフォン向けアプリケーションであって、お客さまのスマートフォンにダウンロードされたものをいいます。</span></li>
  <li><span>「本アプリ利用規約」とは、両社所定の「au PAY アプリ/決済機能利用規約」をいいます。</span></li>
  <li><span>「パスワード等」とは、本アプリ利用規約に定めるログインパスワードおよびアプリパスワードをいいます。</span></li>
  <li><span>「APIサービス利用規定」とは、auカブコム証券所定の「kabu.com APIサービス利用規定」をいいます。</span></li>
  <li><span>「auカブコムAPIサービス」とは、APIサービス利用規定に基づいてauカブコム証券が提供するサービスをいいます。</span></li>
</ul>
<br/>
<h3><b>第2条　API連携（auカブコム証券）サービスの内容</b></h3>
<br/>
<p>API連携（auカブコム証券）サービス（以下「本サービス」という。）とは、本アプリを使用して、アグリゲーションサービスをご利用いただけるサービスです。</p>
<br />
<h3><b>第3条　規約への同意</b></h3>
<br />
<p>本サービスのご利用に際しては、あらかじめ本規約にご同意いただく必要があります。</p>
<br />
<h3><b>第4条　ご利用条件</b></h3>
<br />
<p>お客さまは、本規約にご同意いただいた上で、以下の条件をすべて充足する場合に限り、本サービスを利用することができるものとします。</p>
<ul class='child'>
  <li><span> お客さまがauカブコム証券の証券総合口座を保有していること。</span></li>
  <li><span>あらかじめAPIサービス利用規定にご同意いただいたうえで、本アプリへのアクセス権限の付与に係るauカブコム証券所定の手続が完了しており、かつ、本アプリに付与されたアクセス権限が有効に存続していること。</span></li>
  <li><span>あらかじめ本アプリ利用規約にご同意いただいたうえで、本アプリをお客さまのスマートフォン（但し、指定機種に限るものとします。以下同じ。）において利用できる状態にしておくこと。</span></li>
  <li><span>前号のほか、本アプリ利用規約に定める利用条件をすべて充足していること。</span></li>
  <li><span>本アプリ上で、本サービスを利用するための両社所定の利用設定（以下「サービス利用設定」という。）が行われていること。</span></li>
</ul>
<br />
<h3><b>第5条　本サービスの利用</b></h3>
<br />
<ul class='parent'>
  <li><span>本サービスの利用にあたり、サービス利用設定できるスマートフォンは、お客さまお一人につき1台のみとなります。</span></li>
  <li><span>以下の場合、両社は本サービスの利用をお断りする場合があります。</span>
    <ul class='child ml-1'>
      <li><span>本サービスの提供が技術上その他の理由により困難なとき。</span></li>
      <li><span> お客さまが、本アプリの動作確認済み環境のスマートフォンを有していないとき。</span></li>
      <li><span> その他、両社が適当でないと判断したとき。</span></li>
    </ul>
  </li>
  <li><span>本サービスの利用については、両社が提供する最新の本アプリをご使用ください。</span></li>
  <li><span>サービス利用設定後において、登録スマートフォン（第6条第1項において定義します。）から本アプリを削除した場合には、本アプリを再ダウンロードしたうえで、改めてサービス利用設定を行ってください。</span></li>
  <li><span>auカブコム証券が提供するサービスの利用に必要となるID・パスワードその他の認証情報（以下「外部サービス認証情報」という。）は、お客さまの責任で厳重に管理し、他人に教えたり、紛失・盗難に遭わないよう十分注意するものとします。</span></li>
  <li><span>お客さまは、パスワード等（パスワード等が記載または記録された媒体を含みます。）の紛失もしくは漏洩が判明した場合または本アプリへの不正アクセスが生じた場合、およびこれらの懸念が生じた場合には、すみやかに両社に連絡してください。この連絡を受けたときは、両社は直ちに本サービスを停止する措置を講じます。</span></li>
  <li><span>本サービスの利用にあたり、以下の各号に該当する事象が発生した場合は、両社は、auカブコム証券と連携して情報収集にあたるため、必要に応じ、両社が保有するお客さまの情報をauカブコム証券に対し開示することができるものとします。また、この場合、auカブコム証券においてAPIサービス利用規定に基づくAPIの利用を停止する場合があります。</span>
    <ul class='child ml-1'>
      <li><span>お客さまの情報が外部に流出・漏洩した場合、またはそのおそれがある場合（前項に定める両社への連絡があった場合を含みます。）</span></li>
      <li><span>不正利用が発生した場合、またはそのおそれがある場合</span></li>
    </ul>
  </li>
  <li><span>auカブコム証券によるアクセストークンやお客さま情報（前項により両社が開示した情報を含みますが、これに限りません。）の管理不十分、使用上の過誤、不正使用等により発生した損害または損失は、auカブコム証券が負うものとし、両社は責任を負いません。 </span></li>
  <li><span> 本サービスの利用に伴い、以下に該当する事象が発生した場合に、お客さまに損害が生じたり、お客さま保護上のリスクが生じるおそれがあります。お客さまは、かかるリスクを十分に理解し、同意したうえで、本サービスを利用するものとします。</span>
    <ul class='child ml-1'>
      <li><span>アクセストークン、パスワード等または外部サービス認証情報が流出、漏洩もしくは偽造され、auカブコム証券もしくは両社のシステムが不正にアクセスされ、またはauカブコム証券のシステム障害等により、お客さま情報の流出等が生じる</span></li>
      <li><span> auカブコム証券の責めに帰すべき事由（内部役職員の不正行為、システム管理の不備、お客さま保護態勢の不備等を含みますが、これらに限られません。）によりauカブコム証券のサービス機能停止やお客さま情報の流出等が生じる</span></li>
    </ul>
  </li>
  <li><span>本サービスの利用にあたっては、前各項のほかAPIサービス利用規定の定めに従うものとします。</span></li>
</ul>
<br />
<h3><b>第6条　本サービスの内容</b></h3>
<br />
<ul class='parent'>
  <li><span>お客さまは、お客さまがサービス利用設定をされたスマートフォン（以下「登録スマートフォン」という。）上で本アプリを起動して、本アプリ画面に表示された操作手順にしたがって必要な情報の入力等の操作を行うことにより、本サービスを利用することができます。</span></li>
  <li><span>アグリゲーションサービスによりお客さまに提供される情報は、auカブコム証券と両社との間の契約に基づいてauカブコム証券から両社に提供されるお客さまの情報のうち、両社が指定する情報に限られます。</span></li>
</ul>
<br />
<h3><b>第7条　登録スマートフォン等の管理</b></h3>
<br />
<p><span>お客さまは、登録スマートフォンが第三者の手に渡り、かつ本アプリの利用パスワード（以下「アプリパスワード」という。）等が知られた場合は、当該第三者により本サービスが不正利用されることによりお客さまの情報が外部に漏れたり、お客さまに不正な取引注文等による損害が発生したりする可能性があることを十分認識したうえで、お客さまの責任において登録スマートフォンおよびアプリパスワード等を厳重に管理し、これらを第三者に貸与または開示してはならないものとします。</span></p>
<br />
<h3><b>第8条　権利帰属および遵守事項</b></h3>
<br />
<ul class='parent'>
  <li><span>本アプリその他本サービスに関連するシステムおよびその一切の派生物（以下「本アプリ等」という。）に関する著作権及び特許権等の知的所有権並びにその他一切の権利は各保有者に帰属します。また、アグリゲーションサービスにおいてお客さまに提供される情報に関しては、当該情報サービスの提供元に帰属します。お客さまは、お客さまご自身で利用する以外に本サービスの内容を、その目的の如何に関わらず、複製、加工、再利用又は転載することは一切できません。</span></li>
  <li><span>お客さまは、アグリゲーションサービスにおいてお客さまに提供された情報を第三者へ販売すること、第三者へ再配信すること、第三者と共同で利用すること、情報を閲覧している端末機以外へ転載すること、インターネットアクセスポイントを不明にすることおよび両社またはauカブコム証券のデータベースにアクセスして情報を入手する行為その他スクリーンスクレイピングに類する一切の行為であって、両社がシステムの負荷を増加させると判断する行為を行ってはならないものとします。</span></li>
  <li><span>お客さまは、合法的な目的のためにのみ本サービスを利用するものとします。</span></li>
  <li><span>お客さまは、本アプリ等の複製、逆アセンブル、逆コンパイル又はリバースエンジニアリングや、本アプリ等の全部又は一部の改変を行うことはできません。</span></li>
  <li><span>お客さまは、本アプリ等を第三者に対し、有償、無償を問わず、譲渡、使用許諾その他の方法で使用させてはならず、また、担保の目的に供することはできません。</span></li>
  <li><span>お客さまは、本アプリ等の内容を第三者に開示することはできません。</span></li>
  <li><span>お客さまは、両社の同意を得ることなく両社の商標、社名及びロゴマーク等を使用することはできません。</span></li>
</ul>
<br />
<h3><b>第9条　免責事項</b></h3>
<br />
<ul class='parent'>
  <li><span>両社は、本サービスの信頼性を高めるために最善の努力をしますが、アグリゲーションサービスにおいてお客さまに提供された情報の正確性、適格性、信頼性、適時性を保証するものではありません。</span></li>
  <li><span>両社は、本サービスに関し、本サービスとauカブコムAPIサービスとの連携が常時適切に行われること、お客さまの利用目的に適合すること、連携結果が正確性、適格性、有用性、信頼性、適時性を有すること、auカブコム証券のシステム管理態勢その他のセキュリティレベル、顧客保護態勢、信用性等が十分であることを保証するものではありません。</span></li>
  <li><span>両社は、auカブコム証券の提供するサービスに起因してお客さまに発生したすべての損害について、お客さまに対し、責任を負いません。</span></li>
  <li><span>本サービスに関する技術上の理由または両社の業務上の理由もしくはセキュリティ上の理由（第5条第6項の場合を含みますがこれに限られません。）、保守等の理由やauカブコム証券のシステムの故障、不具合、一時停止等の理由に基づき、お客さまに事前に通知することなく、本サービスの全部または一部が一時的に制限、停止されることがあります。なお、本項に基づく本サービスの全部または一部の一時的な制限、停止によって生じた損害については、両社は責任を負いません。</span></li>
  <li><span>本サービスの利用に関して、本アプリの作動に係る不具合（表示内容の誤謬・逸脱、取引依頼の不能など）、スマートフォンに与える影響およびお客さまが本サービスを利用できないことにより被る不利益、その他一切の不利益について、両社に故意または重大な過失がある場合を除き、両社は一切の責任を負いません。</span></li>
  <li><span>前項のほか、次の各号の事由により、本サービスが利用できなかった場合には、これによって生じた損害については、両社は責任を負いません。</span>
    <ul class='child ml-1'>
      <li><span>災害・事変、裁判所等公的機関の措置等のやむをえない事由が生じた場合。</span></li>
      <li><span>両社が相当と認める安全対策を講じたにもかかわらず、通信回線またはコンピュータ等の障害が生じた場合。</span></li>
      <li><span>両社以外の第三者の責に帰すべき事由による場合。</span></li>
      <li><span>登録スマートフォンまたはインターネット回線の利用不可能な地域または状況下にある場合。</span></li>
      <li><span>お客さまが本アプリの最新版を登録スマートフォンにダウンロードしていない場合。</span></li>
      <li><span>登録スマートフォンが本アプリの動作確認済環境を有していない場合（登録スマートフォンのOS（オペレーションシステム）の更新等により本アプリの動作確認済環境が一時的に失われた場合を含む。）</span></li>
    </ul>
  </li>
</ul>
<br />
<h3><b>第10条　本サービスの変更等</b></h3>
<br />
<ul class='parent'>
  <li><span>両社は、本サービスの内容を変更または停止する場合があります。その場合には、両社は 本アプリのお知らせまたは両社ホームページへ掲載することによりお客さまに告知したうえで、サービス内容を変更または停止するものとします。 </span></li>
  <li><span>前項に定めるほか、auカブコムAPIサービスの内容が変更または停止された場合には、本サービスの内容についても変更または停止される場合があります。この場合も、両社は本アプリのお知らせまたは両社ホームページへ掲載することによりお客さまに告知しますが、止むを得ない場合は変更または停止後に告知が行われる場合があります。</span></li>
</ul>
<br />
<h3><b>第11条　本サービスの中止</b></h3>
<br />
<ul class='parent'>
  <li><span>両社は、お客さまが本規約または両社の定める他の規約に違反した場合、または第4条第1項各号に定める本サービスの利用条件のいずれか一つでも充足していないことが判明した場合は、お客さまに通知することなく、本サービスの提供を中止することができるものとします。 </span></li>
  <li><span>理由の如何を問わず、以下の各号のいずれかの事由に該当する場合、本サービスの提供は自動的に中止されます。</span>
    <ul class='child ml-1'>
      <li><span>お客さまが本アプリの利用を停止された場合</span></li>
      <li><span>auカブコム証券がお客さまに対するauカブコムAPIサービスの提供を中止した場合</span></li>
    </ul>
  </li>
</ul>
<br />
<h3><b>第12条　お客さま申立てによる本サービスの利用中止</b></h3>
<br />
<p><span>登録スマートフォンの紛失その他の理由によりお客さまが本サービスの利用中止を行いたい場合は、両社お問い合わせ窓口へ連絡し、両社所定の手続きを行ってください。</span></p>
<br />
<h3><b>第13条　お客さま情報の取得および利用</b></h3>
<br />
<ul class='parent'>
  <li><span>両社は、お客さまが本サービスを利用された場合において、当該利用に係る情報を自動的に取得します。</span></li>
  <li><span>両社は、お客さまが両社に届け出た情報および前項により取得した情報を、以下の目的のために利用します。また、お客さま個人の識別・特定が通常できない状態に加工したものを作成し、利用または両社が指定する第三者へ提供する場合があります。お客さまは係る情報の利用および提供をあらかじめ同意するものとします。</span>
    <ul class='child ml-1'>
      <li><span>お客さまとの契約（本規約を含みます。）や法律等に基づく権利の行使や義務の履行（取引注文伝達サービスにおいてお客さまの取引注文に係る情報をauカブコム証券に伝達することを含みます。）のため</span></li>
      <li><span>市場調査、マーケティングならびにデータ分析やアンケートの実施等による両社新サービスの開発、研究またはサービス向上のため</span></li>
      <li><span>金融商品やサービスに関する各種ご提案のため</span></li>
      <li><span>auカブコム証券その他の提携会社等の商品やサービスの各種ご提案のため</span></li>
      <li><span>お客さま情報の属性やデータを集計、分析するため</span></li>
      <li><span>その他、お客さまとのお取引を適切かつ円滑に履行するため</span></li>
    </ul>
  </li>
</ul>
<br />
<h3><b>第14条　規約等の準用</b></h3>
<br />
<ul class='parent'>
  <li><span>本サービスに関し、本規約に定めのない事項については、本アプリ利用規約や両社の他の規約の定めを準用し、また、お客さまとauカブコム証券との間の権利義務関係等についてはAPIサービス利用規定が適用されます。</span></li>
  <li><span>本規約において使用する用語の意味は、特に指定のない限り両社所定の本アプリ利用規約において定義した内容に従うものとします。 </span></li>
  <li><span>両社は本サービスの運営業務の一部を業務委託先に委託することができるものとします。</span></li>
</ul>
<br />
<h3><b>第15条　規約の変更</b></h3>
<br />
<p><span>両社は、この規約の内容を変更する場合があります。その場合には、両社は変更日および変更内容を本アプリのお知らせまたは両社ホームページへ掲示することにより告知し、変更日以降は変更後の内容により、取扱うものとします。</span></p>
<br />
<p><span>以上</span></p>
</div>`;
